.store-create-step-one {
  .dropdown-input-long {
    width: 355px;
    margin-top: 10px;
  }
  .dropdown-short {
    width: 180px;
  }
  .radius-container {
    input {
      width: 200px;
    }
  }
}
