@import './utils/Variables.scss';

.main-container {
  display: flex;
  flex-direction: row;
}

.sidebar-container {
  position: fixed;
  width: $sideNavWidth;
  height: 100%;
  background-color: $darkBlue;
  left: -240px;
  z-index: 3;
  overflow-y: scroll;
  transition: left 0.5s;
  &::-webkit-scrollbar {
    position: absolute;
    right: 0;
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $darkBlue;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2a4865;
    border-radius: 8px;
    opacity: 0.65;
  }
}

.sidebar-container-full-width {
  left: 0px;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  transition: padding-left 0.5s;
}
.main-content-padding {
  padding-left: $sideNavWidth;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
}

.bm-burger-button {
  position: fixed;
  width: 18px;
  height: 15px;
  left: 32px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #8c8c8c;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #002148;
  .sidebar-container {
    visibility: visible;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
