.manage-search-container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
  width: calc(100% - 40px - 40px);
  margin: 30px auto 100px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.flex-end-save-button {
  justify-content: flex-end;
}