.coupons-warning {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  color: #ff0000;

  margin: 8px 0px;
}

.coupons-warning-tips {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.coupons-normal {
  font-size: 16px;
  line-height: 24px;

  color: #404040;
  margin: 8px 0px;
}

.coupons-normal-tips {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #979797;
  margin: 0px 0px;
}

.sperate-line {
  background: #c2c2c2;
  height: 1px;
  margin: 38px 0px 8px 0px;
}

.result-area {
  width: 770px;
  max-height: 500px;
  overflow: auto;
}

.grid-area {
  display: grid;
  margin-bottom: 24px;
}

.normal-weight {
  font-weight: 400;
}
