.comment-section-field-input-merchant{
    color:#4A5568;
    margin-top: 20px;
}

.comment-section-field-input-admin{
    color:#4E8DD7;
    margin-top: 20px;
}
.HKTB-amendment{
    font-weight: 800;
}
.merchant-remark{
    font-weight: 800;
}
.payment-settlement-amendment-remark-row {
    margin-left: 20px;
    margin-bottom: 0;
}