.tourcard-selector-image {
  width: 84px;
  height: 62px;

  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;

  margin-left: 13px;
}

.tourcard-info-area {
  margin-left: 11px;

  label {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #404040;
  }

  .gray-label {
    color: #979797;
  }
}
