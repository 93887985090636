@import '../../utils/Variables.scss';

.scroll-container-common {
  align-self: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.header-width {
  width: 100%;
}

.scroll-container-common-header {
  &.desktop {
    display: flex;
  }

  &.mobile {
    display: none;
  }

  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  background: white;
  z-index: 1;
  width: 100%;
  // justify-content: center;

  border-bottom: 1px solid #dadada;
}

.scroll-container-short-screen {
  width: 960px;
}

.caution-area {
  margin-top: 20px;
}

.caution-text {
  font-family: MuliExtraBold;
  font-size: 26px;
  line-height: 40px;
  color: $darkBlue;
  margin-bottom: 0px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.on-saving-area-active {
  margin-top: 30px;
  margin-bottom: 30px;
}

.tab-container-header {
  padding-bottom: 0px;
  border-bottom: unset;

  div.list-section-container-button-and-title {
    margin-top: 0px;
  }
}

.tab-container-content {
  padding: 0px;

  div.tabbar-container {
    padding: 0px;
  }
}

@media screen and (max-width: 789px) {
  .scroll-container-common-header.mobile {
    display: flex;
  }

  .scroll-container-common-header.desktop {
    display: none;
  }

  .scroll-container-common-header {
    padding-left: 15px;
    padding-right: 15px;

    padding-top: 80px;
    border: none;

    // display: flex;
    flex-direction: row;

    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;

      padding: 0px;

      gap: 4px;

      &:hover, &:active, &:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:focus {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      img {
        width: 14px;
        height: 14px;
      }
      span {
        color: #4E8DD7;

        text-align: center;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px;

        margin-top: -2px;
      }
    }

    label {
      margin-left: auto;
      padding-left: 40px;
    }
  }

  .scroll-container-common {
    padding-left: 15px;
    padding-right: 15px;
  }

  .scroll-container-short-screen {
    width: inherit;
  }

  .unsave-prompt {
    .base-prompt-header {
      font-size: 20px;
    }

    .base-prompt-buttons {
      flex-direction: column-reverse;
      gap: 12px;
      width: 90%;
      align-self: center;

      button {
        align-self: center;
        margin: 0px;
        width: 100%;
      }
    }
  }

  *:has(+ .customer-bottom-buttons) {
    display: none;
  }
}
