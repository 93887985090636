.base-simple-table.entitlement-tracking-code-simple-table {
  tr td {
    min-height: 128px;
    max-height: none;
  }
  tr td:nth-child(1) {
    min-width: 266px
  }
  tr td:nth-child(2) {
    width: 96px;
    padding: 8px;
  }
  tr td:nth-child(3), tr td:nth-child(4), tr td:nth-child(5) {
    min-width: 125px;
  }
  tr td:nth-child(5), tr td:nth-child(6), tr td:nth-child(7) {
    width: 300px;
    max-width: 300px;
  }
  tr td:last-child {
    width: 83px;
    max-width: 83px;
  }
}

.tracking-code-batch-manager .dropdown {
  z-index: 1 !important;
}