.channel-show-more-button,
.channels-show-more-content {
  color: #4e8dd7 !important;
  font-size: 14px !important;
}

.membershipInfo-tourCard {
  display: block;
  margin-bottom: -20px;
}

.membershipInfo-tourCard-titleWithPhoto {
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
}

.membershipInfo-tourCard-title {
  display: block;
}

.goodie-bag-view-linked-coupon-campaign {
  color: #4e8dd7;
  cursor: pointer;
}

.goodie-bag-view-linked-coupon-campaign-single {
  margin-bottom: 20px;
}
