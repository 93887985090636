.display-coupon-partner-qr-code {
    background: #ffffff;
    border: 0.749228px solid #c2c2c2;
    border-radius: 5.99382px;
    padding: calc((75px - 2px - 60px) / 2);
  }
  
  .coupon-partner-qr-code-container,
  .coupon-partner-qr-code-container:hover {
    cursor: pointer;
    display: flex;
    width: 75px;
    flex-direction: row;
    position: inherit !important;
    .hover-partner-qr-code-container {
      width: 232px;
      height: 270px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
      background: #ffffff;
      display: flex;
      flex-direction: column;
      .hover-partner-qr-code-title {
        width: 115px;
        height: 16px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #4d5560;
        margin: 24px 70.5px 0 46.5px;
      }
      .hover-coupon-partner-qr-code {
        margin: 16px 48px 0 24px;
        background: #ffffff;
        background: #ffffff;
        border: 0.749228px solid #c2c2c2;
        border-radius: 5.99382px;
        padding: calc((160px - 2px - 120px) / 2);
      }
      .hover-partner-qr-code-description {
        width: 135px;
        height: 14px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.015em;
        color: #14140a;
        margin: 16px 60.5px 0 36.5px;
      }
    }
  }
  