@import '../../utils/Variables.scss';
.display-over-all-limit-container {
  display: flex;
  flex-direction: row;
}
.display-over-all-limit {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
}

.display-over-all-limit-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.profile-section-label-bottom {
  margin-bottom: 0px;
}

.information-section-label-bottom-space{
  margin-top: 30px;
}
.status-waiting{
  width:fit-content;
  color: #d89616;
  background-color: #ffe9b0;
}
.status-agreed{
  width:fit-content;
  color: #178768;
  background: #dff1ec;
}
.status-disagreed{
  width:fit-content;
  color: #d13636;
  background: #fbe1e1;
}