@import '../../../utils/Variables.scss';
.store-hours-area-container {
  flex-direction: column;
  border: 1px dashed #c2c2c2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 30px 18px 30px;
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .add-hours-button {
    align-self: center;
    margin-left: 0px;
    margin-right: auto;
  }

  .add-hours-button-common {
    height: 61px;
    border-top: 1px dashed #c2c2c2;
    padding-top: 20px;
    margin-top: 45px;
  }

  .dash-top {
    margin-top: 30px;
  }

  .week-hour-title-content {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;
  }
  .content-delete-button,
  .content-delete-button:hover {
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
  .create-section-title-without-upper {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: $darkBlue;
    margin: 0px;
  }
}
.error-hour-container {
  border: 1px dashed red;
}

.holiday-hours-close {
  margin-top: 10px;
}

.holiday-hours-delete {
  margin-top: 20px !important;
}
