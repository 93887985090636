.create-mission-step-three {
    .custom-number-input-short {
        width: 120px;
    }
    .custom-number-input-long {
        width: 200px;
        padding: 5px;
    }

    .general-section-add-new {
        margin-top: 30px;
    }
    
    .selected-campaigns-row {
        border: 0px;
        padding: 0px;
    }
}

.create-location-dialog {
    .base-prompt-container {
        padding: 0px;
        .base-prompt-header {
            padding: 30px;
        }
        .base-prompt-other-body {
            margin-top: 0px;
            .store-create-step-one {
                background-color: #FAFBFE;
                .create-section-and-preview-container {
                    margin: 0px;
                    .language-tab-container,
                    .create-step-section-item-first-section,
                    .create-step-section-item {
                        box-shadow: none;
                        border-radius: 0;
                    }

                    .create-step-section-item:last-child {
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                    }
                }
                .store-hours-area-container:last-child {
                    margin-bottom: 0px;
                }
            }
            .base-prompt-buttons {
                margin-top: 30px;
                padding-right: 30px;
            }
        }
        .base-prompt-buttons {
            margin-top: 30px;
            padding-top: 0px;
        }
    }
}
