.preview-container {
  .language-container {
    position: fixed;
    width: 100vw;
    height: 68px;
    z-index: 20;
    top: 0px;
    background: white;
    border-bottom: 1px solid #dadada;

    .web-language-settings {
      top: 20px;
      right: 30px;
      display: flex;
      flex-direction: row;
      position: absolute;
      z-index: 2;
      cursor: pointer;
    }
    .web-language {
      margin-left: 10px;
      margin-right: 10px;
      align-self: center;
      color: black;
      font-size: 15px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      padding: 0px;
      cursor: pointer;
      margin-bottom: 0px;
    }
    .language-icons {
      color: red;
      font-size: 18px;
      align-self: center;
    }
    .language-icons-2 {
      color: rgb(109, 109, 109);
    }
  }
}

.language-icons {
  color: red;
  font-size: 18px;
  align-self: center;
}

.language-icons-2 {
  color: rgb(109, 109, 109);
}
