.edit-mission-location-prompt {
  width: 800px;
  .base-prompt-other-body {
    margin-top: 0px;
  }
  .edit-mission-location-content {
    input {
      width: 200px;
    }
  }
}
