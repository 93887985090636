@import '../../../utils/Variables.scss';

.campain-list-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.campaign-resume-button {
  margin-right: 16px;
}

.star-setting {
  position: absolute;
  margin-top: 65px;
  /* margin-right: 100px; */
  right: 40px;
  z-index: 2;
}

.wechat-filter {
  position: absolute;
  margin-top: 65px;
  margin-right: 312px;
  right: 40px;
  z-index: 2;
}

.campaign-devide-line {
  width: 100%;
  border: 1px solid #dadada;
  height: 1px;
  margin-top: 30px;
}
