.overview-campaigns-ranking-table-title {
    display: flex;
}
.coupon-table-overview .coupon-table-hidden {
    margin-bottom: 80px;
}
.coupon-table-overview .list-filter-list-search-section {
    width: 350px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 12px;
    display: flex;
    margin-left: auto;
    margin-right: 0;
}

.coupon-table-overview .list-filter-search-field {
    margin-left: 8px;
    width: 100%;
    border: 0;
    &:focus {
        outline: 0px;
    }
}

.coupon-table-overview .list-filter-search-icon {
    width: 21px;
}

.coupon-table-overview label {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.coupon-table-overview .common-table-overflow {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    margin: 0px 40px 40px 40px;
    width: auto;
    min-height: 0px;
    padding: 22px 33px;
    margin-top: 34px;
}

.coupon-overview.overview-all-list-table td:nth-child(3),
.coupon-overview.overview-all-list-table th:nth-child(3) {
    border-right: none;
}

.coupon-overview.overview-all-list-table tbody tr:nth-child(odd) {
    background: white;
}
.coupon-overview.overview-all-list-table td {
    border: none;
}

.coupon-overview.overview-all-list-table tbody tr {
    padding: 0px 26px;
}

.coupon-overview.overview-all-list-table tbody tr label {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #626262;
    word-break: break-all;
}

.coupon-table-overview .all-list-scrollable-area-pagination {
    border-top: 1px solid #999999;
}

.coupon-table-overview > div.coupon-list-section.common-table-overflow > table > thead > tr > th > div {
    height: auto;
    padding: 24px 20px;
}

.coupon-table-overview > div.coupon-list-section.common-table-overflow > table > thead > tr > th > div label {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.coupon-table-overview > div.coupon-list-section.common-table-overflow > table > tbody > tr > td {
    height: auto;
    padding: 13px 25px;
}

.coupon-table-overview > .common-table-overflow > table > thead > tr > th > div > img {
    height: auto;
}
.coupon-table-overview > div.coupon-list-section.common-table-overflow > form {
    align-self: flex-end;
}
