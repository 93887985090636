.preview-container {
  .main {
    margin-top: 68px;
    overflow: hidden;
    font-family: 'Futura', 'libel_suit', 'Open Sans', 'Noto Sans TC',
      'Noto Sans SC', Arial, sans-serif, Verdana, Helvetica;
    a {
      text-decoration: none;
      cursor: pointer;
    }
    .offer-terms {
      transform: none;
      opacity: 1;
      height: auto;
      clear: both;
      padding: 0 8.49057vw 4.16667vw 8.49057vw;
      background: #f9f9f9;
      h3 {
        padding-top: 4.16667vw;
        padding-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
      }
      .content {
        max-height: 200px;
        position: relative;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        color: #9b9b9b;

        li {
          padding-left: 10px;
          display: list-item;
          text-align: -webkit-match-parent;
        }
        ol {
          padding: 0;
          margin-left: 25px;
        }
        .view-more {
          color: #ec1f48;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 0;
          vertical-align: bottom;
          padding-top: 50px;
          text-align: right;
        }
        .view-more .a {
          font-size: 18px;
          color: #ec1f48;
        }
      }
    }

    .offer-details-wrapper {
      display: block;
      overflow: hidden;
      .offer-book {
        background-color: #ea8b00;
        position: absolute;
        left: 0;
        z-index: 1;
        overflow-y: auto;
        color: #ffffff;
        vertical-align: top;
        padding: 5.46875vw 0 0 6%;
        font-size: 18px;
        float: left;
        width: 40%;
        height: 100%;

        .offer-book-content {
          width: 75%;
          display: block;
        }
        .logo-detail {
          display: flex;
          flex-direction: row;
        }
        .offer-logo {
          margin-bottom: 50px;
          position: relative;
          height: 55px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 70%;
          padding-right: 10px;
          span {
            font-size: 16px;
            margin-left: 15px;
            line-height: 1.2;
            font-weight: bold;
          }
        }
        .logo-image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background: #ffffff;
        }
        .offer-hour {
          float: right;
          font-size: 14px;
          padding-top: 14px;
          position: absolute;
          right: 0;
          width: 30%;
        }
        .offer-hour:before {
          content: '';
          background-image: url(../../../../assets/images/preview/clock.png);
          width: 24px;
          height: 24px;
          position: absolute;
          left: -35px;
          background-repeat: no-repeat;
          background-position: center center;
        }
        h1 {
          color: #b91b0f;
          font-size: 36px;
          line-height: 1.2;
          padding-bottom: 1.5625vw;
          font-weight: bold;
        }
        .description {
          font-size: 18px;
          padding-bottom: 30px;
          line-height: 28px;
        }
      }
      .offer-book::-webkit-scrollbar {
        width: 10px;
      }

      .offer-book::-webkit-scrollbar-track {
        background: #ea8b00;
      }

      .offer-book::-webkit-scrollbar-thumb {
        background-color: rgb(228, 162, 103);
      }
    }
    .offer-info {
      float: right;
      width: 60%;
      padding: 4.16667vw 9% 50px 9%;

      img {
        width: 100%;
        visibility: hidden;
        display: block;
        border-radius: 20px;
        max-width: 100%;
      }
      h5 {
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        padding-bottom: 20px;
      }
      h3 {
        font-size: 1.5625vw;
        color: #ea8b00;
        padding-bottom: 30px;
        font-weight: bold;
      }
      .image-container {
        width: 100%;
        height: auto;
        padding-bottom: 60px;
        .swiper-pagination-bullet {
          margin: 0px 8px;
        }
        .swiper-pagination-bullet-active {
          background: red;
        }
        .swiper-slide {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          overflow: hidden;
          img {
            visibility: visible;
          }
        }
      }
      .quality-provider {
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        color: #333;
        font-size: 15px;
        padding: 20px 0;
        width: 100%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        margin-top: 70px;
        line-height: 20px;
      }
      .quality-provider:before {
        content: '';
        background: rgba(255, 255, 255, 0.1);
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .offer-quality {
        display: inline-block;
        padding-right: 20px;
        .detail {
          width: auto;
          display: flex;
        }
        .logo {
          display: inline-block;
          vertical-align: middle;
          width: 50px;
          height: 50px;
          -ms-flex: 0 0 50px;
          flex: 0 0 50px;
          max-width: 50px;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          margin-right: 10px;
        }
        img {
          position: absolute;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          width: 100%;
          visibility: visible;
        }
      }
      .details {
        padding-top: 55px;
        h3 {
          color: #ea8b00;
        }
        .content {
          font-size: 18px;
          max-height: 200px;
          position: relative;
          overflow: hidden;
        }
        .view-more {
          color: #ec1f48;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 0;
          background: linear-gradient(
            180deg,
            rgba(249, 249, 249, 0) 0%,
            #f9f9f9 60%,
            #f9f9f9 100%
          );
          vertical-align: bottom;
          padding-top: 50px;
          text-align: right;
          a {
            font-size: 18px;
            color: #ec1f48;
            text-decoration: none !important;
          }
        }
      }
      .itinerary-image-container {
        width: 100%;
        height: auto;
        padding-bottom: 60px;
        .swiper-pagination-bullet {
          margin: 0px 8px;
        }
        .swiper-pagination-bullet-active {
          background: red;
        }
        .swiper-slide {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          overflow: hidden;
          .our-itinerary-details {
            flex-direction: column;
          }
          img {
            width: 100% !important;
            height: auto !important;
          }
          .content {
            margin-top: 10px;
          }
          li {
            background: white;
            border-radius: 20px;
          }
          li > div {
            margin-left: 0px;
            border-left: none;
            padding: 0px;
            margin-top: 0px;
          }
        }
      }
      .our-itinerary {
        transform: none;
        opacity: 1;
        height: auto;
        padding-top: 55px;
        h3 {
          color: #ea8b00;
        }
        ol {
          counter-reset: item;
          list-style-type: none;
        }
        ul {
          list-style: none;
        }
        li {
          display: list-item;
          text-align: -webkit-match-parent;
        }
        li:before {
          content: counter(item, decimal-leading-zero) '';
          counter-increment: item;
          vertical-align: top;
          background-color: #fd8924;
          border-radius: 50%;
          padding: 17px 18px;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          margin-left: -11px;
          line-height: 24px;
        }
        li > div {
          margin-left: 14px;
          border-left: 6px solid rgba(0, 0, 0, 0.1);
          padding: 0 34px 18px;
          margin-top: -35px;
        }

        .tt {
          display: none;
        }

        div.more-mask-layer {
          display: none;
        }

        .our-itinerary-details {
          background: #fff;
          border-radius: 20px;
          padding: 20px;
          height: 100%;
          display: flex;
          margin-top: 10px;
          img {
            background: #fff;
            height: 127px;
            width: 170px;
            visibility: visible;
            display: block;
          }
          .content {
            font-size: 18px;
            max-height: 200px;
            position: relative;
            overflow: hidden;
            padding-left: 30px;

            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            h5 {
              padding-bottom: 10px;
            }
            p {
            }
          }
        }
      }
      .book {
        padding-top: 55px;
        transform: none;
        opacity: 1;
        height: auto;
        .content {
          font-size: 18px;
          max-height: 200px;
          position: relative;
          overflow: hidden;
          ul {
            margin-left: 20px;
            list-style: none;
          }
          ol {
            list-style: none;
          }
          li {
            text-indent: -0.6em;
            display: list-item;
            text-align: -webkit-match-parent;
          }
          li:before {
            content: '\2022';
            color: #ea8b00;
            font-weight: bold;
            display: inline-block;
            padding-right: 10px;
          }
          .view-more {
            display: none;
            color: #ec1f48;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            vertical-align: bottom;
            padding-top: 50px;
            text-align: right;
            background: linear-gradient(
              180deg,
              rgba(249, 249, 249, 0) 0%,
              #f9f9f9 60%,
              #f9f9f9 100%
            );
          }
        }
      }
      .offer {
        padding-top: 55px;
        .content {
          font-size: 12px;
          color: #9b9b9b;
          max-height: 200px;
          position: relative;
          overflow: hidden;
          a {
            color: #ec1f48;
            text-decoration: underline;
          }
          .view-more {
            color: #ec1f48;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            vertical-align: bottom;
            padding-top: 50px;
            text-align: right;
            background: linear-gradient(
              180deg,
              rgba(249, 249, 249, 0) 0%,
              #f9f9f9 60%,
              #f9f9f9 100%
            );
            a {
              color: #ec1f48;
              font-size: 18px;
              text-decoration: none !important;
            }
          }
        }
      }
      .provided {
        font-weight: bold;
        font-size: 18px;
        padding-top: 40px;
      }
    }
  }
  @media (min-width: 769px) and (max-width: 1247px) {
    .offer-info {
      padding: 4.16667vw 6% 50px 6% !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info {
      width: 100% !important;
      padding: 40px 25px 40px 25px !important;
      margin-top: -100px !important;
    }
  }
  @media print {
    .offer-info .swiper-wrapper {
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }
  @media print {
    .offer-info .swiper-slide {
      display: none !important;
    }
  }
  @media print {
    .offer-info .swiper-slide.swiper-slide-active {
      display: block !important;
      width: 100% !important;
    }
  }
  @media print {
    .offer-info img {
      visibility: visible !important;
    }
  }
  @media print {
    .offer-info .slide-inner {
      visibility: hidden !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info h3 {
      font-size: 20px !important;
      padding-bottom: 15px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info h5 {
      font-size: 14px !important;
      padding-bottom: 15px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info .content {
      font-size: 14px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info .view-more a {
      font-size: 12px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info .book,
    .offer-info .offer,
    .offer-info .details {
      padding-top: 25px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary {
  //       padding-right: 0 !important;
  //       margin-right: -25px !important;
  //     }
  //   }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary ol {
  //       display: flex !important;
  //       flex-wrap: nowrap !important;
  //     }
  //   }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary ol li {
  //       display: flex !important;
  //       height: auto !important;
  //     }
  //   }
  @media screen and (max-width: 1023px) {
    .offer-info .our-itinerary ol li:before {
      background-color: #024e02 !important;
      position: absolute !important;
      left: 13px !important;
      top: 0px !important;
      padding: 17px 19px !important;
    }
  }
  //   @media screen and (min-width: 1024px) {
  //     .offer-info .our-itinerary ol li > div {
  //       margin-left: 14px !important;
  //       border-left: 6px solid rgba(0, 0, 0, 0.1) !important;
  //       padding: 0 34px 15px !important;
  //       margin-top: -35px !important;
  //     }
  //   }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary ol li > div {
  //       margin-right: 5px !important;
  //       width: 100% !important;
  //     }
  //   }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary ol li > div .our-itinerary-details {
  //       display: block !important;
  //     }
  //   }
  //   @media screen and (min-width: 1024px) {
  //     .offer-info .our-itinerary ol li > div .our-itinerary-details img {
  //       height: 127px !important;
  //       width: auto !important;
  //     }
  //   }
  //   @media screen and (min-width: 1024px) {
  //     .offer-info .our-itinerary ol li > div .our-itinerary-details .content {
  //       padding-left: 30px !important;
  //     }
  //   }
  //   @media screen and (max-width: 1023px) {
  //     .offer-info .our-itinerary ol li > div .our-itinerary-details .content {
  //       padding: 10px !important;
  //     }
  //   }
  @media (max-width: 768px) {
    .offer-info .provided {
      padding-top: 25px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
      font-size: 14px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info .quality-provider {
      font-size: 12px !important;
      line-height: 16px !important;
      margin-top: 45px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-info .quality-provider .detail span {
      padding-top: 10px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .offer-info .quality-provider .logo {
      width: 35px !important;
      height: 35px !important;
      -ms-flex: 0 0 35px !important;
      flex: 0 0 35px !important;
      max-width: 35px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .image-container .swiper-button-prev,
    .image-container .swiper-button-next {
      display: none !important;
    }
  }
  @media (max-width: 1239px) {
    .offer-book {
      top: 62px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book {
      float: none !important;
      width: 100% !important;
      position: static !important;
      padding: 40px 24px 100px 24px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book > div {
      position: relative !important;
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .offer-logo {
      padding-bottom: 20px !important;
      min-height: 75px !important;
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .offer-logo span {
      padding-right: 23px !important;
    }
  }
  @media screen and (min-width: (max-width: 1670px)) {
    .offer-book .offer-hour {
      float: none !important;
      margin-left: 35px !important;
      padding-bottom: 30px !important;
    }
  }
  @media (max-width: 1024px) {
    .offer-book .offer-hour {
      padding-left: 32px !important;
      position: static !important;
      width: auto !important;
      float: none !important;
      padding-bottom: 20px !important;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .offer-book .offer-hour:before {
      left: 233px !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .offer-hour:before {
      left: 0 !important;
      background-size: 80% !important;
      background-position: center !important;
    }
  }
  @media (max-width: 1023px) {
    .offer-book h1 {
      font-size: 24px !important;
      width: 90% !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .description {
      padding-bottom: 0 !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .btn-book {
      padding-top: 0px !important;
      position: static !important;
      height: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .btn-book.fixed .btn-cta {
      position: fixed !important;
      bottom: 5px !important;
      left: 0 !important;
      right: 0 !important;
      width: 90% !important;
      z-index: 10 !important;
      margin: auto !important;
    }
  }
  @media (max-width: 768px) {
    .offer-book .quality-provider {
      font-size: 14px !important;
      padding-bottom: 20px !important;
    }
  }
  @media screen and (max-width: 1023px) {
    [data-type='free-tours']
      .offer-details-wrapper
      .offer-info
      .our-itinerary
      ol
      li:before {
      background-color: #024e02 !important;
    }
  }
  @media (max-width: 767px) {
    .offer-terms {
      padding: 0 24px 40px 24px !important;
    }
  }
  @media (max-width: 767px) {
    .offer-terms h3 {
      padding-top: 40px !important;
      font-size: 14px !important;
      padding-bottom: 15px !important;
    }
  }
  @media (max-width: 767px) {
    .offer-terms .content {
      font-size: 12px !important;
    }
  }
  @media (max-width: 767px) {
    .offer-terms .content .view-more a {
      font-size: 12px !important;
    }
  }
}
