.overview-row-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
  margin-left: 38px;
  margin-top: 10px;
}
.overview-campaigns-title {
  margin-top: 30px;
  display: flex;
}

.overview-coupons-title {
  margin-top: 0;
  display: flex;
}

.overview-row {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 30px 38px 10px 40px;
}

.overview-campaigns-status-information,
.overview-coupons-status-information {
  margin-left: auto;
  margin-right: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #979797;
  text-transform: none;
}

.campaign-over-page-info {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-top: 23px;
  width: 100%;
  text-align: end;
  padding: 0px 35px;
}

.campaign-overview-coupon-tips-area {
  min-width: 400px;
}

.overview-row-campaigns {
  max-width: 950px;
}

.overview-row-coupons {
  max-width: 660px;
}