$image-size: 90px;
.store-qr-code-container,
.store-qr-code-container:hover {
  width: 120px;
  height: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  .preview-image-container {
    width: 120px;
    height: 120px;
    padding: calc(#{120px-$image-size} / 2);
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    background: #fff;
  }
  .preview-image {
    width: $image-size;
    height: $image-size;
  }
  .qr-code-download-container {
    width: 290px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
    background: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .qr-code-download-image {
    width: 275px;
    height: 388px;
    margin-left: 8px;
    margin-top: 6px;
  }
  .qr-code-download-button {
    padding: 0;
    margin: 6px 0px 6px 8px;
    width: 133px;
    height: 44px;
    border-radius: 8px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .button-container {
    display: flex;
    flex-direction: row;
  }
}

.store-code-list-table > tbody > tr > td:nth-child(3) {
  position: unset;
}
