.sortable-tour-card {
  border: 1px dashed #c2c2c2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 10px;
  &.error {
    border: 1px dashed #ff0000;
  }
}
.tour-card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 2px dashed #dadada;
  .tour-card-left {
    display: flex;
    align-items: center;
    .tour-card-index {
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      margin-right: 18px;
      min-width: 27px;
    }
    .tour-card-cover-img {
      width: 80px;
      height: 60px;
      margin-right: 11px;
    }
    .tour-card-title {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
    }
    .tour-card-id {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #979797;
    }
  }
  img.tour-card-delete-icon {
    width: 25px;
    cursor: pointer;
    margin-right: 30px;
  }
}

.exist-button-margin-top {
  margin-top: 28px;
}

.exist-button {
  padding: 7px 16px !important;
  font-size: 14px !important;
}

.second-section .add-new-tour-card {
  margin-top: 38px;
  font-size: 14px !important;
  &.error {
    margin-top: 12px;
  }
}
