.per-target-market-limit-single-area {
  display: flex;
  flex-direction: column;
  border-bottom: 2px dashed #dadada;
  margin-bottom: 25px;
  padding-bottom: 20px;
}

.per-target-market-limit-area-title {
  display: flex;
  flex-direction: row;
  position: relative;
}

.per-target-market-limit-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  margin-top: auto;
  margin-bottom: auto;
}

.per-target-market-limit-delete {
  margin-left: 13px;
  cursor: pointer;
}

.per-target-market-limit-available {
  margin-right: 0;
  margin-left: auto;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #404040;
}

.per-target-market-limit-available.other-market {
  position: absolute;
  right: 0;
  left: auto;
}

.number-input-area-other-market {
  margin-top: 0;
}

.per-target-market-limit-available .per-target-market-limit-available-value {
  font-weight: bold;
  margin-left: 3px;
}

.per-target-market-limit-single-area .tips-message {
  margin-top: 8px;
}