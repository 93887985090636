@import '../../../utils/ColorVariables.scss';
.btn-download {
  margin-right: 10px;
}

.prompt-with-remarks {
  margin-top: 5px;
}

div.customer-interests-mobile {
  display: none;
}

.customer-action-remarks {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .custom-textarea-remakrs {
    width: 720px;
    height: 200px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404040;

    resize: none;

    &:focus, &:focus-visible {
      border-color: #4e8dd7;
      outline-color: #4e8dd7;
    }
  }

  .checkbox-container {
    color: var(--aillia-10-black-1, #404040);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding-left: 30px;

    .checkmark {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 900px) {
    .custom-textarea-remakrs {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .custom-textarea-remakrs {
      width: 100%;
      height: 160px
    }
  }
  @media screen and (max-width: 490px) {
    & {
      max-width: 280px;
    }
  }
}

@media screen and (max-width: 789px) {
  .filter-area {
    width: 295px !important;
  }

  .customer-filter-mobile {
    top: 80px !important;
    right: 15px !important;
  }

  .customer-mobile {
    display: flex;
    flex-direction: column;
    .header {
      background-color: #FFF;
      padding: 17px 15px 0;
    }

    .loading-area {
      height: calc(100vh - 60px - 224px - 16px);
      margin-top: 16px;
      background: transparent;

      img {
        width: 100%;
      }
    }

    .total-count {
      color: #979797;

      text-align: right;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      margin: 16px 15px;
    }
  }

  .customer-search-form {
    display: flex;
    flex-direction: column;

    width: 100% !important;

    button {
      margin: 24px 0px 12px;
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .customer-mobile-list::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .customer-mobile-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    padding: 0px 15px;
  }

  .customer-card {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(172, 192, 215, 0.34);
    border-radius: 8px;
    margin: 16px 0px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: relative;

    .customer-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      // height: 20px;
      padding-bottom: 8px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e9e9e9;

      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;

      span {
        color: #4E8DD7;
        line-height: normal;
      }

      .customer-card-title-status {
        display: flex;
        align-items: center;

        margin-left: auto;
        line-height: 16px;

        color: #404040;
      }
    }

    .customer-card-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.35px;
      color: #404040;

      div {
        display: flex;
        flex-direction: row;
        color: #4E8DD7;

        width: 100%;

        img {
          margin-left: auto;
        }
      }

      span {
        word-wrap: break-word;
      }
    }
  }

  .customer-card:first-child {
    margin-top: 0px;
  }

  .action-dropdown {
    margin-top: 16px;
    // z-index: 100;
    margin-left: auto;

    div.devide-line {
      border: 0.5px solid #e9e9e9;
      width: 100%;
    }

    div.custom-dropdown-menu {
      width: 200px;
      padding: 15px 0px 10px;

      z-index: 100;

      color: #404040;

      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;

      div.action-list {
        padding: 0px 20px;

        .custom-dropdown-item {
          line-height: 40px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .action-group-title {
    font-weight: 800 !important;
    line-height: 40px;
  }

  .action-group-title-sperate-line {
    border-top: 1px solid #404040;
    margin-bottom: 9px;
  }

  .record-loading {
    height: 100%;
    background-color: inherit;
  }

  .customer-interests {
    div.v2-selector-multiple-container .multiple-selector-v2-search-container {
      display: none;
    }

    div.customer-interests-mobile {
      display: flex;

      flex-wrap: wrap;
      gap: 16px;

      label {
        display: flex;
        height: 40px;
        padding: 10px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 8px;
        border: 2px solid #C2C2C2;

        color: #404040;

        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &.selected {
          color: #4E8DD7;
          border-color:#4E8DD7;
          box-shadow: none
        }
      }


    }
  }

  .customer-bottom-buttons {
    width: 100%;

    button.base-save-button-temp-button {
      margin-left: 20px;
    }

    button.base-save-button {
      width: 100%;
    }
  }

}
