.map-address-input-area {
  display: flex;

  input,
  input:focus,
  input:active {
    width: 100%;

    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    outline: 0px;
    box-shadow: none;
  }

  input:focus,
  input:active {
    border: 1px solid #ccc;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  button, button:hover, button:active, button:focus {
    width: 28px;
    // margin-left: -30px;

    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 5px 0px 9px 2px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    margin-bottom: 0px;
  }
}


.one-location-area {
  display: flex;
  border: 1px solid #DADADA;
  border-radius: 10px;
  margin-top: 24px;
  padding: 0px 24px 24px;

  .one-map {
    width: 100%;
  }

  button.map-delete-button {
    height: 38px;
    margin-top: 30px;
    label {
      margin-top: auto;
    }
  }

  button.map-delete-button.hidden {
    display: none;
  }
}

button.add-location-more {
  margin-top: 24px;
  margin-left: 0px;
  margin-right: auto;
}