@import '../../utils/Variables.scss';
.list-new-line {
  white-space: pre-wrap;
}

.list-nowrap {
  white-space: nowrap;
}

.rank-area-extra-width {
  width: 130px;
}

.rank-extra {
  margin-top: -40px;
}

$withStatusWidth: calc((100vw - 240px - 80px - 52px - 130px - 90px - 130px));
$listFifteenPercentWidth: calc((100vw - 240px - 80px - 394px) * 0.15);

.common-name-area-with-status {
  min-width: 182px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px - 130px) * 0.25);
}
.common-name-area-with-status-count-1 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 1)
  );
}
.common-name-area-with-status-count-2 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 2)
  );
}
.common-name-area-with-status-count-3 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 3)
  );
}
.common-name-area-with-status-count-4 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 4)
  );
}
.common-name-area-with-status-count-5 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 5)
  );
  //   width: $listFifteenPercentWidth;
}
.common-name-area-with-status-count-6 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 6)
  );
  //   width: $listFifteenPercentWidth;
}

.common-name-area-without-status {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * 0.25);
}

// .common-name-area-without-status-count-2 {
//   min-width: 122px;
// }

.common-name-area-without-status-count-1 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 1));
}
.common-name-area-without-status-count-2 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 2));
}
.common-name-area-without-status-count-3 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 3));
}
.common-name-area-without-status-count-4 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 4));
}
.common-name-area-without-status-count-5 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 5));
}
.common-name-area-without-status-count-6 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 6));
}

.common-status-area,
td.common-status-area {
  width: 130px;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  align-self: center;
}
.common-status-order {
  width: 233px;
}

.common-action-area {
  width: 90px;
}

.earnning-rule-list-scrollable-area-other {
  min-width: $listItemMin;
  width: calc((100vw - 240px - 52px - 122px - 153px) * (0.75 / 4));
}

.common-table-overflow {
  // overflow-y: scroll;
  min-height: 300px;
}

.sortable-helper td {
  display: flex;
  align-items: center;
}

.sortable-helper .all-list-scrollable-area-actions {
  height: 100%;
  .dropleft {
    margin: 0 auto;
  }
}

td.all-list-scrollable-area-actions.all-list-scrollable-area-table-item-common
  > .dropleft {
  position: unset;
  display: flex;
  justify-content: center;
}

.campaign-list-section {
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px 0px rgba(154, 161, 171, 0.3);
  background: white;
  flex-direction: column;
  display: flex;
  width: 100%;
  position: relative;
  height: 715px;
}

.campaign-list-scrollable-area-other {
  min-width: $listItemMin;
  width: $listFifteenPercentWidth;
}

.campaign-list-tab-container {
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #dadada;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
  // min-width: 1186px;
  width: 100%;
  &.hide-tab-bar a {
    visibility: hidden;
  }
}

.campaign-list-tab-container a.nav-item.nav-link {
  // margin: 0px 20px;
  margin: 0px 15px;
}

.base-display-item-photo {
  object-fit: cover;
}

.base-rank-container {
  display: flex;
  flex-direction: row;
}

.common-status-with-mininal {
  min-width: 200px;
}

.table-shadow-left {
  width: calc(
    122px + 122px + (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * 0.25
  );
  min-width: calc(122px + 122px + 182px);
  height: 630px;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  background: transparent;
  pointer-events: none;
}
.table-shadow-of-customer-activity-logs {
  width: 394px;
  min-width: unset;
}

.table-shadow-right {
  width: 122px;
  position: absolute;
  top: 0;
  height: 630px;
  right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  background: transparent;
  pointer-events: none;
}

// .all-list-scrollable-area-table-item-common .show.dropleft .dropdown-for-item-menu.dropdown-menu.show {
//   position: absolute !important;
//   top: 0px !important;
//   left: -205px !important;
// }

.all-list-component-action-header-div .overview-row-title {
  margin-left: 0px;
  letter-spacing: 0.5px;
  font-family: Mulish;

}

