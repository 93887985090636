.dashboard-top5-customer-nationality-card {
    min-width: 465px;
    height: 567px;
    .dashboard-card-title {
        margin-top: 30px;
        margin-left: 30px;
        line-height: 22.5px;
    }
    .dashboard-card-body {
        position: relative;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;

        .loading-area {
            width: 100%;
            height: 290px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            margin-top: 110px;
        }
    }
    .dashboard-card-head {
        color: #979797;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        .dashboard-card-name {
            left: 0;
        }
        .dashboard-card-number {
            position: absolute;
            right: 0;
        }
    }
    .dashboard-coupon-card-data {
        .dashboard-coupon-card-data-single-name {
            margin-bottom: 0;
            font-weight: 600;
            line-height: 15px;
            color:#000000;
            max-width: 350px;
            width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .dashboard-coupon-card-data-single-value {
            margin-left:80px;
            margin-bottom: 0;
            font-weight: 600;
            line-height: 15px;
            color:#000000;
            position: absolute;
            right: 0;
        }
        .non-progress {
            .progress-bar {
                display: none;
            }
        }
        .progress {
            height: 24px;
            margin: 5px 0px 25px 0px;
            .progress-bar {
                min-width: 50px;
            }
            .dashboard-card-data-percentage {
                margin-bottom: 4px;
            }
        }
    }
    .dashboard-card-data-defined {
        color: #979797;
        position: absolute;
        left: 30px;
        bottom: 30px;
        font-family: Mulish;
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        .dashboard-card-data-collected {
            display: block;
        }
        .dashboard-card-data-undefined {
            display: block;
        }
        .dashboard-card-data-number {
            font-weight: 800;
            margin-left: 4px;
        }
    }
    .dashboard-coupon-card-data-top3 {
        .progress-bar {
            background: linear-gradient(270deg, #EA5C7A 0%, #F4A172 100%);
        }
    }
    .dashboard-coupon-card-data-others {
        .progress-bar {
            background: linear-gradient(270deg, #00A9C8 0%, #5383DB 42.19%, #7375E2 100%);;
        }
    }
}

.dashboard-top5-customer-nationality-card .dashboard-card-title .dashboard-top5-nationality-label-caution {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #979797;
    text-transform: none;
}