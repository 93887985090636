.flex-row {
  flex-direction: row !important;
}

.group-choice {
  display: flex;
}

.group-choice div.filter-list-items-area {
  margin-top: 0px;
  min-height: fit-content;
}

.group-choice label.checkbox-container {
  margin-top: 0px;
  margin-left: 15px;
}

.group-choice label.create-section-label-bottom-space {
  margin-top: 0px;
  width: 80px;
}
