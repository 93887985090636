.coupon-campaign-section-input {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  margin-right: 10px;
  padding: 12px 8px;
}

.coupon-campaign-section-input:focus {
  border: 1px solid #c2c2c2;
  outline: none;
}

.coupon-campaign-section-caution-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  margin-top: 8px;
}

.coupon-margin-top {
  margin-top: 31px;
}

.coupon-limit-area {
  display: grid;
  border: 1px dashed #c2c2c2;
  border-radius: 10px;
  margin-top: 17px;
  padding: 0 23px 23px;
}

.coupon-channel-sperate-line {
  border: 1px dashed #fff;
  margin-top: 31px;
  background: #dadada;
  border-radius: 8px;
}

.campagin-coupon-allocation {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #404040;
}

.campagin-coupon-allocation-number {
  color: #4E8DD7;
}

.campaign-coupon-tips-messages {
  margin-top: 8px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #979797;
}

.per-target-market-limit-area {
  border: 1px dashed #c2c2c2;
  border-radius: 10px;
  padding: 20px 30px;
}

.per-target-market-limit-area {
  margin-top: 30px;
  max-width: 805px;
}

.per-target-market-limit-dropdown {
  max-width: 346px;
}

.per-head-download-for-each-time {
  margin-top: 30px;
}
