@import '../../utils/Variables.scss';
.breadcrumb-container {
  height: 24px;
  margin-top: 20px;
  width: 100%;
}
.breadcrumb-container ol {
  background-color: transparent;
}

.breadcrumb {
  padding: 0px;
  margin: 0px;
}

.breadcrumb-item a,
li.breadcrumb-item {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
  flex: none;
  order: 2;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.breadcrumb-item a {
  display: inline-flex;
}

.breadcrumb-item a label {
  cursor: pointer;
}

.breadcrumb-item a:hover {
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: url('../../assets/images/bread_crumb.svg');
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
}

.breadcrumb-item.active {
  color: #8c8c8c;
  display: inline-block;
}

.html {
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

li.breadcrumb-item > label {
  max-width: 596px;
  overflow: hidden;
  text-overflow: ellipsis;
}
