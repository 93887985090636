@import '../../../utils/Variables.scss';
.base-buttons-main {
  margin-right: 40px;
}

.base-buttons-main-left-margin {
  margin-left: auto;
}

.base-buttons-secondary {
  margin-right: 20px;
  margin-left: auto;
}

.base-buttons-item {
  height: auto;
  align-self: flex-end;
}

.base-buttons-link {
  padding: 0px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: $navyBlue;
  align-self: center;
  margin-right: auto;
}

.edit-button {
  margin-top: -6px;
  margin-left: 24px;
}
