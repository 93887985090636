@import '../../../../utils/Variables.scss';

.option-time-string {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: $deepGrey;
  margin-right: 40px;
}

.option-link {
  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: $linkBtnColor;
}

.option-field {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  align-items: center;
  color: $inkBlack;
}
