.date-range-container {
  display: flex;
  flex-flow: column;
  margin: 28px 0 28px;
  .ant-picker {
    width: 400px;
    .ant-picker-separator {
      display: flex;
    }
  }
}

.date-range-tips {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin: 12px 0;
}

.date-range-dropdown {
  width: 400px;
}

.blank-tip-block {
  height: 12px;
}
