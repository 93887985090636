.loading-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.loading-area {
  width: 100%;
  height: 667px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.loading-icon-align-center {
  align-self: center;
}
