.payment-settlement-coupon-use-record-section-button{
    border: 2px solid;
    background-color: #4e8dd7;
    color: #ffffff;
    border-color: #4e8dd7;
    font-family: Muli;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    height: 40px;
    box-shadow: none;
    padding: 6px 25px;
    border-radius: 100px;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 40px;
    margin-left: 0px;
    margin-right: auto;
}