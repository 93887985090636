@import '../../../utils/Variables.scss';

.payment-settlement-save-area {
  margin-right: 0;
  margin-left: auto;
  float: right;
}

.payment-settlement-disagree-content {
  display: block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
}

.payment-settlement-disagree-remark {
  display: block;
  font-size: 16px;
  width: 600px;
  height: 108px;
  left: 1px;
  top: 30px;
  border: 1px solid #c2c2c2;
  margin-top: 10px;
  resize: none;
}

.payment-settlement-amendment-content {
  display: block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
}

.payment-settlement-amendment-number {
  display: block;
  width: 500px;
  height: 40px;
  left: 1px;
  top: 30px;
  border: 1px solid #c2c2c2;
  margin-top: 10px;
}

.payment-settlement-amendment-remark {
  display: block;
  width: 600px;
  height: 108px;
  left: 1px;
  top: 30px;
  border: 1px solid #c2c2c2;
  margin-top: 10px;
  resize: none;
}

.payment-settlement-alert-info-prompt {
  overflow-y: hidden !important;
}

.payment-settlement-amendment-content-error-field {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0000;
  padding: 0px;
  margin-top: 8px;
}