.physical-coupon-overview-table {
    border-color: #dadada;
    margin-top: 28px;
    width: 100%;
    td:nth-child(1) {
      width: 14%;
    }
  
    td:nth-child(2) {
      width: 37%;
    }
  
    td:nth-child(3) {
      width: 39%;
    }
  
    td:nth-child(4) {
      width: 10%;
    }
  
    thead {
      width: 100%;
    }
  
    td {
      font-family: 'Mulish';
      font-style: normal;
    }
  
    thead > tr > td {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      padding: 35px 35px 38px 30px;
    }
  
    tbody > tr > td {
      font-weight: 400;
      padding: 15px 35px 18px 30px;
      font-size: 14px;
      line-height: 21px;
    }
  
    .physical-coupon-batch-export-button {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */
      color: #4e8dd7;
      background: transparent;
      border: none;
    }
    .physical-coupon-batch-export-button:focus {
      border: none;
    }
  }
  