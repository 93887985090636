/***** Offer Basic Detail Component Begin *****/

$starColor: #ff580a;

body {
  // background-color: #f8f8f8 !important;
  height: auto;
  font-family: 'Open Sans Regular', arial, sans-serif !important;
}

.dhk .preview-container {
  background: #f8f8f8 !important;
  // margin-top: 20px;
  -webkit-font-smoothing: subpixel-antialiased;

  p,
  pre,
  code {
    margin-bottom: 10px;
  }

  p:last-child,
  pre:last-child,
  code:last-child {
    margin-bottom: 0;
  }
}

.offer-basic-detail-container {
  position: relative;
  width: 100%;
  // font-family: "Microsoft JhengHei","Microsoft Yahei";
}

.offer-basic-detail-pc {
  max-width: 1440px;
  width: 71%;
  margin: 120px auto 50px auto;
  font-size: 17px;
  display: flex;
  display: -webkit-flex;
}

.offer-basic-detail-pc .detail-left {
  width: 56.6%;
}

.offer-basic-detail-pc .detail-left .detail-photos-wrapper {
  width: 100%;
  margin-bottom: 0;
  max-width: 628px;
}

.offer-basic-detail-pc .detail-left .detail-photos-wrapper .swiper-container {
  width: 100%;
  margin-bottom: 2.5em;
}

.offer-basic-detail-pc .swiper-container > .swiper-pagination-bullets {
  bottom: 0px;
  position: relative;
}

.offer-basic-detail-mb
  .swiper-container-horizontal
  > .swiper-pagination-bullets {
  bottom: 20px;
}
.offer-basic-detail-mb .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 2px solid red;
  opacity: 1;
  background: #fff;
}

.offer-basic-detail-pc
  .detail-left
  .detail-photos-wrapper
  .swiper-container
  .swiper-slide
  img {
  width: 100%;
  height: auto;
}

.offer-basic-detail-pc
  .detail-left
  .detail-photos-wrapper
  .swiper-container
  .swiper-pagination-bullet-active,
.offer-basic-detail-mb
  .detail-photo-cover-wrapper
  .swiper-container
  .swiper-pagination-bullet-active {
  background: #0c8b50;
  opacity: 0.2;
}

.offer-basic-detail-pc
  .detail-left
  .detail-photos-wrapper.star
  .swiper-container
  .swiper-pagination-bullet-active {
  background: $starColor;
  opacity: 0.5;
}

.offer-basic-detail-mb
  .detail-photo-cover-wrapper
  .swiper-container
  .swiper-pagination-bullet-active {
  background: red;
  opacity: 1;
}

.float-left {
  float: left !important;
}

.sharing__list {
  margin-top: 37px;
  padding: 0;
}

.sharing__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.offer-basic-detail-pc
  .detail-left
  .social-media-list-container
  .sharing__container.float-left,
.offer-basic-detail-mb .sharing__container.float-left {
  padding: 0;
  float: none !important;
  width: 100%;
}

.sharing__item {
  margin-right: 5px;
  cursor: pointer;
}

li.sharing__item {
  g.icon-hover__showed path {
    fill: #000000;
    // transition: fill 2s;
    transition: transform 0.3s ease 0s;
    // animation: animate-pulse 0.3s;
  }

  g.icon-hover__hidden path {
    fill: #000000;
    // transition: line 3s;
    transition: transform 0.3s ease 0s;
    // animation: animate-pulse 0.3s;
  }
}
li.sharing__item:hover {
  g.icon-hover__showed path {
    fill: #0c8b50;
  }

  g.icon-hover__hidden path {
    fill: #0c8b50;
  }
}

li.sharing__item.star:hover {
  g.icon-hover__showed path {
    fill: $starColor;
  }

  g.icon-hover__hidden path {
    fill: $starColor;
  }
}

@keyframes animate-pulse {
  0% {
    transform: translate(0%, -100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
// .sharing__container {
//   padding: 0 20px;
// }

// .offer-basic-detail-pc .sharing__container .float-left .sharing__list {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   list-style: none;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
// }

.offer-basic-detail-pc .detail-left .detail-content-wrapper {
  margin-bottom: 2em;
}

.offer-basic-detail-pc
  .detail-left
  .detail-content-wrapper
  .detail-content-title {
  color: #0c8b50;
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.offer-basic-detail-pc .detail-html-content,
.offer-basic-detail-pc .detail-html-content p,
.offer-basic-detail-pc .detail-html-content li,
.offer-basic-detail-pc .detail-html-content div,
.offer-basic-detail-pc .detail-html-content a {
  font-size: 15px;
  line-height: 25px;
  color: black;
}

.offer-basic-detail-pc .detail-html-content > div:not(:last-child),
.offer-basic-detail-pc .detail-html-content > p:not(:last-child),
.offer-basic-detail-pc .detail-html-content > section:not(:last-child),
.offer-basic-detail-pc .detail-html-content > article:not(:last-child),
.offer-basic-detail-pc .detail-html-content > ul:not(:last-child),
.offer-basic-detail-pc .detail-html-content > ol:not(:last-child) {
  margin-bottom: 10px;
}

.offer-basic-detail-pc .detail-html-content ul > li {
  list-style: disc outside;
}

.offer-basic-detail-pc .detail-html-content ol > li {
  list-style: decimal outside;
}

.offer-basic-detail-pc .detail-html-content a {
  text-decoration: underline;
}

.offer-basic-detail-pc .detail-html-content a:hover {
  color: #0c8b50;
}

.offer-basic-detail-pc .detail-left .offer-rule-tabs {
  margin-top: 75px;
  margin-bottom: 75px;
}

.offer-basic-detail-pc .offer-rule-tabs .offer-rule-tab-titles {
  // display: inline-block;
  display: flex;
  padding: 5px 0px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.offer-rule-tab-titles {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;

  white-space: nowrap;
  cursor: pointer;

  // position: relative;
  width: 100%;
  height: 100%;
  // z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.offer-rule-tab-titles::-webkit-scrollbar {
  display: none;
}

// /* Hide scrollbar for Chrome, Safari and Opera */
// .offer-rule-tab-titles-test::-webkit-scrollbar {
//   display: none;
// }

.offer-basic-detail-pc
  .detail-left
  .offer-rule-tabs
  .offer-rule-tab-titles
  .offer-rule-tab-title {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.25em;
  // margin-right: 3em;
  cursor: pointer;
  padding-bottom: 0.3em;
  display: inline-block;
  // max-width: 145px;
  vertical-align: bottom;

  // display: inline-block;
  width: auto;
  height: 1.25em;
  // font-size: 1em;
  // font-weight: bold;
  // line-height: 1.25em;
  // cursor: pointer;

  &:hover {
    color: #0c8b50;
  }
}

.offer-basic-detail-pc
  .detail-left
  .offer-rule-tabs
  .offer-rule-tab-titles
  .offer-rule-tab-title.active {
  color: #0c8b50;
  border-bottom: 2px solid #0c8b50;
}

.offer-basic-detail-pc .detail-left .offer-rule-tabs .offer-rule-tab-content {
  margin-top: 1.5em;
}

.offer-basic-detail-pc .detail-right.detail-box {
  position: absolute;
  right: calc((100vw - 71vw) / 2);
  width: calc(30.743vw - 20px);
  // width: calc(30.46875%);
  // right: 12.5%;
  // top: 100px;
  // border: 1px solid #eaeaea;
  // z-index: 2;

  // position: relative;
  // width: calc(43.3% - 20px);
  border: 1px solid #eaeaea;
  margin-left: 20px;
}

.offer-rule-tab-content .applicable-stores-wrapper .area-wrapper {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding: 0 40px;
  background-color: #f7f7f7;
}

.offer-rule-tab-content .applicable-stores-wrapper .more-stores-text-wrapper {
  border-top: 1.5px solid #f5f5f5;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-bottom: 10px;
  box-sizing: content-box;

  cursor: pointer;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .more-stores-text-wrapper
  .more-stores-text {
  font-size: 14px;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .area-wrapper
  .region-wrapper,
.offer-rule-tab-content
  .applicable-stores-wrapper
  .area-wrapper
  .district-wrapper {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  height: 40px;
  display: block;

  select {
    margin: 0;
  }

  div.filterable-toogle-area {
    background-color: #fff;

    label.btn-link-text-label {
      color: #333;
    }
  }

  div.dropdown-menu {
    padding: 0px;

    ul.filterable-dropdown-menu-select-area {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 0px;
      max-height: 200px;

      a {
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;
        height: auto;
        color: #999;
        line-height: 25px;
      }

      a.filterable-item-active {
        background-color: rgba(204, 204, 204, 0.3);
        color: #333;
      }

      a:hover {
        background-color: #ccc;
        color: #333;
      }
    }
  }

  div.filterable-dropdown-sigle-selected {
    div.filterable-toogle-area {
      background-color: #0c8b50;

      label.btn-link-text-label {
        color: #fff;
      }
    }

    div.dropdown-menu {
      background-color: #0c8b50;

      ul.filterable-dropdown-menu-select-area {
        a {
          color: #fff;
        }

        a.filterable-item-active {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.3) !important;
        }

        a:hover {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.3) !important;
        }
      }
    }
  }

  div.filterable-dropdown-sigle-star-selected {
    div.filterable-toogle-area {
      background-color: $starColor;

      label.btn-link-text-label {
        color: #fff;
      }
    }

    div.dropdown-menu {
      background-color: $starColor;

      ul.filterable-dropdown-menu-select-area {
        a {
          color: #fff;
        }

        a.filterable-item-active {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.3) !important;
        }

        a:hover {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.3) !important;
        }
      }
    }
  }
}

.select2-container {
  margin: 0;
}

.dhk .select2-container {
  width: 100% !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-hidden-accessible {
  border: 0 !important;
  padding: 0 !important;
}

.dhk
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .select2-container--default
  .select2-selection--single {
  padding: 0;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.dhk .select2-container--default .select2-selection--single {
  height: auto;
}

.dhk
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 16px;
  padding-right: 20px;
  color: #000;
  font-size: 16px;
  line-height: 40px;
}

.dhk
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0;
}

selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single {
  cursor: pointer;
}

.dhk
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  width: 21px;
  height: 15px;
  top: 12.5px;
  right: 5px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  left: 50%;
  margin-left: -4px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  margin-top: -2px;
}

.dhk
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.offer-basic-detail-mb .detail-box {
  .offer-hint-ct {
    padding: 0 !important;
    margin-top: 10px !important;
  }
}

.detail-box.detail-box-star {
  background-color: $starColor !important;
  color: #fff;

  .offer-labels .offer-label {
    background-color: #ffdda8 !important;
    color: $starColor !important;
  }

  .offer-label:after {
    border-top-color: #ffdda8 !important;
    border-bottom-color: #ffdda8 !important;
  }

  label.offer-brand-title,
  label.offer-short-desc p,
  label.offer-short-desc ul,
  label.offer-short-desc ul > li,
  div.offer-name {
    color: #fff !important;
  }

  .offer-hint-ct {
    display: flex;
    align-items: center;
    padding: 0 3em;
    line-height: 18px;
    margin: -10px 0 20px 0;

    img {
      width: 28px;
      height: 18px;
      margin-right: 7px;
      flex-shrink: 0;
    }
  }

  .offer-hint {
    font-size: 20px;
    line-height: 1.35em;
  }

  .add-to-my-e-wallet-btn-ct {
    position: relative;
    height: 70px;
    padding: 10px 0;

    .add-to-my-e-wallet-btn {
      background-color: #ffdda8 !important;

      position: absolute;
      display: flex;
      display: -webkit-flex;
      height: 2.75em;
      left: 20px !important;
      width: calc(100% - 40px) !important;
      align-items: center;
      justify-content: center;
      border: 1px solid #ffffff;
      border-radius: 1px;

      span {
        color: $starColor !important;
        cursor: pointer;
      }

      &:hover {
        span {
          color: red;
        }
      }
    }
  }

  .box-content::-webkit-scrollbar {
    background-color: $starColor;
    width: 10px;
  }

  .box-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    border: 3px solid transparent;
    background-clip: content-box;
  }
}

// .offer-basic-detail-pc .detail-left .detail-photos-wrapper .swiper-container .swiper-pagination-bullet-active

.offer-basic-detail-mb .add-to-my-e-wallet-btn.star-btn {
  background-color: #ffdda8 !important;
  span {
    color: $starColor !important;
  }
}

.offer-basic-detail-mb .offer-labels {
  position: absolute;
  top: 0.775em;
  left: 0;
}

.offer-basic-detail-mb .offer-labels .offer-label {
  position: relative;
  float: left;
  margin-top: 0.6em;
  margin-right: 1.3333em;
  height: 2.0667em;
  line-height: 2em;
  padding: 0 0.6667em 0 0.6667em;
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  .offer-basic-detail-pc .detail-right.detail-box {
    right: calc(50% - 720px);
    width: 585px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .offer-basic-detail-pc {
    width: 1100px;
  }

  .offer-basic-detail-pc .detail-right.detail-box {
    right: calc(50% - 550px);
    width: 455px;
  }

  .generic-tac-content-container .generic-tac-content {
    width: 1100px !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .offer-basic-detail-pc {
    width: 900px;
  }

  .offer-basic-detail-pc .detail-right.detail-box {
    right: calc(50% - 450px);
    width: 365px;
  }

  .generic-tac-content-container .generic-tac-content {
    width: 900px !important;
  }
}

@media screen and (max-width: 1300px) {
  .offer-basic-detail-pc {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .offer-basic-detail-pc {
    font-size: 15px;
  }

  .detailPrompt {
    width: calc(100vw - 144px) !important;
    height: calc(100vh - 366px) !important;
  }

  // .detailPrompt .store-info-item {
  //   width: max-content;
  // }
}

@media screen and (max-width: 1024px) {
  .generic-tac-content-container .generic-tac-content {
    margin: 0 10% !important;
  }
}

.offer-basic-detail-pc .detail-right.detail-box .box-header {
  position: relative;
  min-height: 3em;
}

.offer-basic-detail-pc .detail-right.detail-box .box-header .offer-labels {
  // float: left;
  max-width: calc(100% - 100px);
  margin-top: 0.775em;
  margin-bottom: 1.225em;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-header
  .offer-labels
  .offer-label {
  position: relative;
  float: left;
  margin-right: 1.3333em;
  margin-top: 0.6em;
  background-color: #0c8b50;
  height: 2.0667em;
  line-height: 2.1333em;
  padding: 0 0.6667em 0 0.6667em;
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-header
  .offer-labels
  .offer-label:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 1.0333em solid #0c8b50;
  border-right: 0.8em solid transparent;
  border-bottom: 1.0333em solid #0c8b50;
  position: absolute;
  right: -0.75em;
  top: 0;
}

.offer-basic-detail-pc .detail-right.detail-box .box-header .offer-share {
  position: absolute;
  height: 4em;
  line-height: 4em;
  right: 2em;
}

.offer-basic-detail-pc .detail-right.detail-box .box-header .offer-share:hover {
  cursor: pointer;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-header
  .offer-share
  .offer-share-ico {
  height: 1.104em;
  position: relative;
  margin-right: 0.2em;
}

.offer-basic-detail-pc .detail-right.detail-box .box-header .offer-share span {
  display: inline-block;
  color: #0c8b50;
  font-family: 'Microsoft JhengHei', 'Microsoft Yahei';
  font-size: 1em;
  font-weight: bold;
}

.offer-basic-detail-pc .detail-right.detail-box .box-content .offer-brand {
  min-height: 2em;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  display: flex;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-brand
  .offer-brand-ico {
  height: 2em;
  width: 2em;
  border-radius: 1em;
  margin-right: 0.5em;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-brand
  .offer-brand-title {
  font-size: 20px;
  color: black;
}

.offer-basic-detail-pc .detail-right.detail-box .box-content {
  padding: 0 3em;
  margin-bottom: 1em;

  max-height: 450px;
  overflow-y: auto;
}

.box-content::-webkit-scrollbar {
  // display: none;
  background-color: #fff;
}

// .box-content::-webkit-scrollbar-thumb {
//   background: #000;
// }

.offer-basic-detail-pc .detail-right.detail-box .box-content .offer-name {
  font-size: 2.25em;
  line-height: 1.2em;
  color: #0c8b50;
  font-weight: bold;
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // max-height: 6em; /* fallback */
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  // white-space: nowrap;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  ul
  > li {
  list-style: disc outside;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  ol
  > li {
  list-style: decimal inside;
}

.offer-basic-detail-pc .detail-right.detail-box .box-content .offer-short-desc {
  // padding: 0.4em 0;
  // font-size: 1em;
  line-height: 1.35em;
  color: black;

  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;

  * {
    font-size: 20px !important;
  }
}

.offer-short-desc.star {
  color: #fff !important;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  p,
.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  div,
.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  section,
.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  article,
.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-short-desc
  li {
  font-size: 1em;
  line-height: 1.35em;
  color: black;
}

.offer-short-desc.star p,
.offer-short-desc.star div,
.offer-short-desc.star section,
.offer-short-desc.star article,
.offer-short-desc.star li {
  color: #fff !important;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-logo-labels {
  padding: 0.5em 0;
  overflow: hidden;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label {
  border-top: 1px solid #e2e2e2;
  float: left;
  padding: 0.4em 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label:last-child {
  border-bottom: 1px solid #e2e2e2;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label
  .logo-label-ico {
  float: left;
  // height: 1.6em;
  // margin-right: 0.5em;

  height: 1.8em;
  width: 1.8em;
  border-radius: 0.9em;
  margin-right: 0.2em;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label
  .logo-label-desc {
  float: left;
  font-size: 0.8em;
  line-height: 2.1333em;
  min-height: 2.1333em;
}

.offer-basic-detail-pc .detail-right.detail-box .add-to-my-e-wallet-btn {
  position: absolute;

  height: 2.75em;
  line-height: 2.75em;
  background-color: #0c8b50;

  width: calc(100% + 2px);

  display: flex;
  display: -webkit-flex;

  left: -1px;
  // width: calc(100% - 40px);
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 1px;
  // box-shadow: 0 0 20px rgb(0 0 0 / 16%);
}

.offer-basic-detail-pc .detail-right.detail-box .add-to-my-e-wallet-btn:hover {
  cursor: pointer;
}

.offer-basic-detail-pc
  .detail-right.detail-box
  .add-to-my-e-wallet-btn
  .add-to-my-e-wallet-btn-ico {
  display: inline-block;
  height: 1.452em;
  position: relative;
}

.offer-basic-detail-pc .detail-right.detail-box .add-to-my-e-wallet-btn span {
  // font-family: 'Microsoft JhengHei', 'Microsoft Yahei';
  font-size: 1em;
  font-weight: bold;
  color: white;
}

.offer-basic-detail-mb {
  font-size: 20px;
}

.offer-basic-detail-mb .detail-photo-cover-wrapper {
  position: relative;
  margin-top: 72px;
}

.offer-basic-detail-mb .detail-photo-cover-wrapper .detail-photo-cover {
  width: 100%;
}

.offer-basic-detail-mb .detail-wrapper .offer-labels {
  position: absolute;
  top: 0.775em;
  left: 0;
  overflow: hidden;
}

.offer-basic-detail-mb .detail-wrapper .offer-labels .offer-label {
  position: relative;
  float: left;
  margin-top: 0.6em;
  margin-right: 1.3333em;
  background-color: #0c8b50;
  height: 2.0667em;
  line-height: 2.1333em;
  padding: 0 0.6667em 0 0.6667em;
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
}

.offer-basic-detail-mb .detail-wrapper .offer-labels .offer-label:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 1.0333em solid #0c8b50;
  border-right: 0.8em solid transparent;
  border-bottom: 1.0333em solid #0c8b50;
  position: absolute;
  right: -0.75em;
  top: 0;
}

.offer-basic-detail-mb .detail-wrapper {
  // padding: 0 10%;
  margin-bottom: 30px;
}

.offer-basic-detail-mb div.detail-wrapper > div:first-child {
  margin: 0 10%;
}

.offer-basic-detail-mb div.detail-wrapper > div:not(:first-child) {
  padding: 0 10%;
}

.offer-basic-detail-mb .detail-wrapper .detail-box {
  position: relative;
  border: 1px solid #eaeaea;
  padding: 4em 1.5em 1.6em;
  top: -1.2em;
  background-color: white;
  z-index: 2;
}

.offer-basic-detail-mb .detail-wrapper .detail-box .offer-share {
  position: absolute;
  height: 0.75em;
  right: 1em;
  top: 1em;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .offer-share
  .offer-share-ico {
  width: 100%;
}

// .offer-basic-detail-mb .detail-wrapper .detail-box .box-content .offer-brand {
//   overflow: hidden;
//   margin-bottom: 1em;
// }

.offer-basic-detail-mb .detail-wrapper .detail-box .box-content .offer-brand {
  min-height: 1.5em;
  margin-bottom: 1em;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-brand
  .offer-brand-ico {
  height: 1.5em;
  width: 1.5em;
  border-radius: 0.75em;
  margin-right: 0.4em;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-brand
  .offer-brand-title {
  float: left;
  line-height: 2.308em;
  font-size: 0.65em;
  color: black;
  display: block;
  width: 60%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.616em; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.offer-basic-detail-mb .detail-wrapper .detail-box .box-content .offer-name {
  font-size: 1.25em;
  line-height: 1.2em;
  color: #0c8b50;
  font-weight: bold;
  margin-bottom: 0.8em;
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // max-height: 6em; /* fallback */
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  // white-space: nowrap;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc {
  margin-bottom: 1.5385em;
  font-size: 0.65em;
  line-height: 1.35em;
  color: black;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  p,
.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  div,
.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  section,
.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  article,
.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  li {
  font-size: 16px;
  line-height: 1.35em;
  color: black;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  ul
  > li {
  list-style: disc outside;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-short-desc
  ol
  > li {
  list-style: decimal outside;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-logo-labels {
  padding: 0.4em 0;
  overflow: hidden;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label {
  border-top: 1px solid #e2e2e2;
  float: left;
  padding: 0.5em 0;
  width: 100%;

  display: flex;
  align-items: center;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label:last-child {
  border-bottom: 1px solid #e2e2e2;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label
  .logo-label-ico {
  float: left;
  height: 1.8em;
  border-radius: 50%;
  margin-right: 0.2em;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-box
  .box-content
  .offer-logo-labels
  .offer-logo-label
  .logo-label-desc {
  float: left;
  font-size: 0.65em;
  line-height: 1.3077em;
  min-height: 1.3077em;
}

.offer-basic-detail-mb .detail-wrapper .detail-content-wrapper {
  margin-bottom: 1.5em;
}

.offer-basic-detail-mb
  .detail-wrapper
  .detail-content-wrapper
  .detail-content-title {
  color: #0c8b50;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.star-detail-content-title {
  color: $starColor !important;
}

.offer-basic-detail-mb .detail-html-content,
.offer-basic-detail-mb .detail-html-content p,
.offer-basic-detail-mb .detail-html-content li,
.offer-basic-detail-mb .detail-html-content div,
.offer-basic-detail-mb .detail-html-content a {
  font-size: 13px;
  line-height: 20px;
  color: black;
}

.offer-basic-detail-mb .detail-html-content > div:not(:last-child),
.offer-basic-detail-mb .detail-html-content > p:not(:last-child),
.offer-basic-detail-mb .detail-html-content > section:not(:last-child),
.offer-basic-detail-mb .detail-html-content > article:not(:last-child),
.offer-basic-detail-mb .detail-html-content > ul:not(:last-child),
.offer-basic-detail-mb .detail-html-content > ol:not(:last-child) {
  margin-bottom: 8px;
}

.offer-basic-detail-mb .detail-html-content ul > li {
  list-style: decimal outside;
}

.offer-basic-detail-mb .detail-html-content ol > li {
  list-style: decimal outside;
}

.offer-basic-detail-mb .detail-html-content a:hover {
  text-decoration: underline;
}

.offer-basic-detail-mb .detail-html-content a:hover {
  color: #0c8b50;
}

.offer-basic-detail-mb
  .offer-rule-tabs
  .offer-rule-tab-titles
  .offer-rule-tab-title {
  display: inline-block;
  // text-align: center;
  height: 1.25em;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 1.25em;
  cursor: pointer;
  padding-bottom: 0.3em;
}

.offer-basic-detail-mb
  .offer-rule-tabs
  .offer-rule-tab-titles
  .offer-rule-tab-title:not(:last-child) {
  // margin-right: 0.8em;
}

.offer-basic-detail-mb
  .offer-rule-tabs
  .offer-rule-tab-titles
  .offer-rule-tab-title.active {
  color: #0c8b50;
  border-bottom: 2px solid #0c8b50;
}

.offer-basic-detail-mb .offer-rule-tabs .offer-rule-tab-content {
  margin-top: 1em;
}

.offer-basic-detail-mb .add-to-my-e-wallet-btn {
  // position: fixed;
  // display: block;
  // height: 2.75em;
  // line-height: 2.75em;
  background-color: #0c8b50;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // text-align: center;

  position: fixed;
  display: flex;
  display: -webkit-flex;
  height: 2.75em;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  align-items: center;
  justify-content: center;
  z-index: 70;
  border: 1px solid #ffffff;
  border-radius: 1px;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0, 0.16);
}

.offer-basic-detail-mb .add-to-my-e-wallet-btn:hover {
  cursor: pointer;
}

.offer-basic-detail-mb .add-to-my-e-wallet-btn .add-to-my-e-wallet-btn-ico {
  display: inline-block;
  height: 1.452em;
  position: relative;
}

.offer-basic-detail-mb .add-to-my-e-wallet-btn span {
  // font-family: 'Microsoft JhengHei', 'Microsoft Yahei';
  font-size: 1em;
  font-weight: bold;
  color: white;
}

/***** Offer Basic Detail Component End *****/

/***** Generic TAC Content Component Start *****/

.more-offers-container {
  position: relative;
  width: 100%;
  z-index: 1;
}

.more-offers-container .more-offers-bg-shadow {
  height: 100px;
  background-image: linear-gradient(
    to bottom,
    rgba(233, 247, 239, 0) 0,
    rgba(233, 247, 239, 1) 100%
  );
}

.more-offers-container .more-offers-wrapper {
  height: 200px;
  padding-bottom: 50px;
  background-image: linear-gradient(
    to bottom,
    rgba(233, 247, 239, 1) 0,
    rgba(233, 247, 239, 0.1) 100%
  );
  background-color: #f8f8f8;
}

.offer-basic-detail-mb .detail-html-content ol,
.offer-basic-detail-mb .detail-html-content ul,
.offer-basic-detail-pc .detail-html-content ol,
.offer-basic-detail-pc .detail-html-content ul {
  padding-inline-start: 0;
  // padding-left: 20px;
  padding-left: 20px;
}

.generic-tac-content-container {
  position: relative;
  background-color: #f8f8f8;
  padding: 50px 0 40px 0;
  z-index: 3;
}

.generic-tac-content-container .generic-tac-content {
  max-width: 1440px;
  width: 71%;
  margin: 0 auto;
  font-size: 20px;
}

.generic-tac-content-container .generic-tac-content .generic-tac-title {
  color: black;
  font-size: 1em;
  line-height: 1.3em;
  font-weight: bold;
  margin-bottom: 1em;
}

.generic-tac-content-container .generic-tac-content .generic-tac-desc-wrapper {
  max-height: 80px;
  overflow: hidden;
}

.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc-wrapper-auto {
  height: auto;
}

.generic-tac-content-container .generic-tac-content .generic-tac-desc ol,
.generic-tac-content-container .generic-tac-content .generic-tac-desc ul {
  padding-inline-start: 0;
  // padding-left: 25px;

  padding-left: 25px;
}

.generic-tac-content-container .generic-tac-content .generic-tac-desc ul > li,
.generic-tac-content-container .generic-tac-content .generic-tac-desc ol > li {
  list-style: decimal outside;
}

.generic-tac-content-container .generic-tac-content .generic-tac-desc,
.generic-tac-content-container .generic-tac-content .generic-tac-desc p,
.generic-tac-content-container .generic-tac-content .generic-tac-desc li,
.generic-tac-content-container .generic-tac-content .generic-tac-desc div,
.generic-tac-content-container .generic-tac-content .generic-tac-desc a {
  font-size: 14px;
  line-height: 20px;
  color: black;
  overflow-wrap: break-word;
}

.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > div:not(:last-child),
.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > p:not(:last-child),
.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > section:not(:last-child),
.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > article:not(:last-child),
.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > ul:not(:last-child),
.generic-tac-content-container
  .generic-tac-content
  .generic-tac-desc
  > ol:not(:last-child) {
  margin-bottom: 8px;
}

.generic-tac-content-container .generic-tac-content .more-mask-layer {
  height: 30px;
}

.generic-tac-content-container .generic-tac-content .more-mask-layer .more-btn {
  font-size: 13px;
  color: #0c8b50;
  cursor: pointer;
  margin-top: 10px;
}

/***** Generic TAC Content Component End *****/

.offer-rule-tabs
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list {
  padding: 0 40px;
  background-color: white;
}

.offer-rule-tabs
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store {
  display: flex;
  display: -webkit-flex;
  border-bottom: 1.5px solid #f5f5f5;
  cursor: pointer;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store
  .store-content {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 18px 10px;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store
  .store-content {
  flex: 1;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store
  .store-content
  .store-name {
  font-weight: 600;
  font-size: 14px;
}

.detailPrompt {
  width: 520px;
  max-height: 400px;
  overflow-y: scroll;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store
  .store-content
  .store-info-item,
.detailPrompt .store-info-item {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
}

.detailPrompt .store-info-item:not(:first-child) {
  margin-top: 20px;
}

.applicable-stores-wrapper
  .store-list
  .store
  .store-content
  .store-info-item
  .item-ico,
.detailPrompt .store-info-item .item-ico {
  margin-right: 12px;
}

.applicable-stores-wrapper
  .store-list
  .store
  .store-content
  .store-info-item
  .item-content,
.detailPrompt .store-info-item .item-content {
  font-size: 13px;
  color: #747474;
}

.detailPrompt .store-map-container {
  width: 100%;
  height: 340px;
}

.offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list
  .store
  .store-arrow {
  width: 28px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.store-business-hour .store-business-hour-content {
  width: 100%;
}

.store-business-hour .store-business-hour-content,
.detailPrompt .store-info-item .item-content {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #404042;
}

.store-business-hour
  .store-business-hour-content
  .store-business-hour-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 13px;
}

.store-business-hour
  .store-business-hour-content
  .store-business-hour-content-wrapper
  .store-business-hour-col1 {
  flex: 1;
  word-wrap: break-word;
  width: 1px;
}

.store-business-hour
  .store-business-hour-content
  .store-business-hour-content-wrapper
  .store-business-hour-col2 {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.offer-basic-detail-mb {
  font-size: 20px;
}

.offer-basic-detail-mb
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .area-wrapper {
  padding: 0;
}

.offer-basic-detail-mb
  .offer-rule-tab-content
  .applicable-stores-wrapper
  .store-list {
  padding: 0 20px;
  background-color: white;
}

.star-offer-rule-tab.active {
  color: $starColor !important;
  border-bottom: 2px solid $starColor !important;
}

.star-offer-rule-tab:hover {
  color: $starColor !important;
}

.sep-wrapper.swiper-slide {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0 20px;
}

.offer-basic-detail-mb .sep-wrapper.swiper-slide {
  padding: 0 10px !important;
}

.sep {
  width: 1px;
  height: 100%;
  background-color: black;
}

@media screen and (max-width: 768px) {
  .offer-basic-detail-mb
    .offer-rule-tab-content
    .applicable-stores-wrapper
    .area-wrapper {
    flex-direction: column;
    justify-content: center;

    .region-wrapper,
    .district-wrapper {
      width: 100%;
    }
  }

  .offer-rule-tab-content
    .applicable-stores-wrapper
    .area-wrapper
    .region-wrapper,
  .offer-rule-tab-content
    .applicable-stores-wrapper
    .area-wrapper
    .district-wrapper {
    div.filterable-dropdown-area {
      max-width: none;
    }

    div.custom-title-dropdown {
      margin-right: 0px;
    }
  }

  .dhk-preview-prompt-dialog {
    align-items: flex-end;
    margin: 0px !important;

    .modal-content {
      max-width: 100%;
    }

    .base-prompt-header {
      max-width: calc(100vw - 42px) !important;
    }

    .dhk-preview-prompt {
      padding: 20px;
      .detailPrompt {
        width: calc(100vw - 42px) !important;
        height: calc(100vh - 217px) !important;
      }
    }
  }

  .swiper-slide img {
    width: 100% !important;
  }
}

@media screen and (max-width: 490px) {
  .modal-content {
    min-width: inherit !important;
  }

  // .dhk-preview-prompt {
  //   padding: 20px;
  //   .detailPrompt {
  //     width: calc(100vw - 42px) !important;
  //     height: calc(100vh - 366px) !important;
  //   }
  // }
}

.dhk-preview-prompt-dialog {
  .base-prompt-header {
    max-width: 520px;
  }
}

.dhk .preview-container .our-itinerary {
  h3 {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 0.5em;

    color: #298b50;
  }

  ol {
    counter-reset: item;
    list-style-type: none;

    padding-left: 0px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;

    margin-bottom: 30px;
  }

  li > div {
    display: flex;
  }

  // li::marker {
  //   color: #34a582 !important;
  //   font-size: 36px;
  // }

  div.tt {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    font-size: 18px;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
    margin-right: 20px;

    background-color: #298b50 !important;
    color: #fff !important;
  }

  div.our-itinerary-details {
    width: fit-content;

    img {
      max-width: 250px !important;
      height: 260px;
    }

    div.content {
      font-size: 14px !important;
      line-height: 20px !important;
      color: black;
      overflow-wrap: break-word;

      h5 {
        font-size: 15px;
        line-height: 30px;
        font-weight: bold;
        margin-top: 10px;

        color: #298b50;
      }
    }
  }
}

.dhk .preview-container .our-itinerary.star {
  div.tt {
    background-color: $starColor !important;
  }

  div.our-itinerary-details > div.content > h5 {
    color: $starColor;
  }

  h3 {
    color: $starColor;
  }
}

.dhk .preview-container .offer-basic-detail-mb .our-itinerary {
  padding: 0px !important;

  h3 {
    padding: 0 10%;
  }

  .itinerary-image-container {
    div.swiper-slide {
      // width: 250px;
      height: 420px;

      li > div:first-child {
        flex-direction: column;
      }

      div.more-mask-layer {
        height: 20px;
      }

      div.more-btn {
        font-size: 12px;
        line-height: 20px;
        color: #298b50;
      }

      // margin-right: 12px;
    }

    .our-itinerary-details > div.content > div:first-child {
      display: flex;
      padding: 10px 0;

      div.tt {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
        margin-right: 15px;
      }

      h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
        width: calc(100% - 45px);
        margin-top: 5px;
      }
    }

    .our-itinerary-details > div.content {
      // height: 140px;
      overflow: hidden;
      p {
        font-size: 14px !important;
        line-height: 20px !important;
        color: black;
        overflow-wrap: break-word;
      }
    }
  }
}

.dhk .preview-container .offer-basic-detail-mb .our-itinerary.star {
  .itinerary-image-container div.swiper-slide div.more-btn {
    color: $starColor;
  }

  .our-itinerary-details > div.content > div:first-child > h5 {
    color: $starColor;
  }
}

// .header {
//   padding-left: 30px;
//   display: flex;
//   align-self: flex-start;
//   width: 100%;
//   background-color: #fff;
//   border-bottom: 6px solid #e2002c;
//   height: 91px;
//   align-items: center;
//   z-index: 2;

//   // position: fixed;

//   .header-logo {
//     width: 136px;
//     height: 55px;
//     object-fit: contain;
//     margin-right: auto;
//   }
// }
