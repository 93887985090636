img.survey-form-delete-icon {
  width: 25px;
  cursor: pointer;
  margin-right: 30px;
  display: inline-block;
  margin-left: 13px;
}

.survey-form-option-input {
  width: 700px!important;
}

.survey-form-flex-div{
  margin-top: 12px;
}

.create-option-label-top-space{
  margin-top: 30px;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
 }