.dashboard-top10-coupon-acquired-card {
  min-width: 465px;
  height: 1038px;
  .dashboard-card-title {
    // margin-top: 30px;
    // margin-left: 30px;
    line-height: 22.5px;
  }
  .dashboard-card-choice-buttons {
    height: 40px;
    margin-top: 20px;
    // margin: 20px 30px 0 30px;
    width: auto;
    display: flex;
  }

  .filter-button-group {
    width: 100%;
    .btn-primary {
      color: #979797;
      background-color: #fff;
      border-color: #979797;
    }

    .filter-button-group-item {
      width: 50%;
      height: 40px;
    }

    .filter-button-group-item,
    .filter-button-group-item:left {
      background-color: #fff;
      border-color: #979797;
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      /* identical to box height, or 171% */

      text-align: center;

      height: 40px;
      color: #979797;
    }

    // .filter-button-group-item:active,
    // .filter-button-group-item:focus
    .filter-button-selectd,
    .filter-button-group-item:hover {
      background: #ebf2fc;
      border: 1.5px solid #4e8dd7;
      box-sizing: border-box;
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 131% */

      height: 40px;
      color: #4e8dd7;
    }

    .filter-button-selectd-0 {
      border-radius: 20px 0 0 20px;
    }

    .filter-button-selectd-1 {
      border-radius: 0 20px 20px 0;
    }
  }

  .dashboard-card-body {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;

    .loading-area {
      width: 100%;
      height: 667px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      margin-top: 110px;
    }
  }
  .dashboard-card-head {
    color: #979797;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    .dashboard-card-name {
      left: 0;
    }
    .dashboard-card-number {
      position: absolute;
      right: 0;
    }
  }
  .dashboard-coupon-card-data {
    .dashboard-coupon-card-data-single-name {
      margin-bottom: 0;
      line-height: 15px;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      max-width: 350px;
      width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .dashboard-coupon-card-data-single-value {
      margin-left: 80px;
      margin-bottom: 0;
      line-height: 15px;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      position: absolute;
      right: 0;
    }
    .non-progress {
      .progress-bar {
        display: none;
      }
    }
    .progress {
      height: 24px;
      margin: 5px 0px 25px 0px;
      .progress-bar {
        min-width: 50px;
      }
      .dashboard-card-data-percentage {
        margin-bottom: 4px;
      }
    }
  }
  .dashboard-coupon-card-data-top3 {
    .progress-bar {
      background: linear-gradient(270deg, #ea5c7a 0%, #f4a172 100%);
    }
  }
  .dashboard-coupon-card-data-others {
    .progress-bar {
      background: linear-gradient(
        270deg,
        #00a9c8 0%,
        #5383db 42.19%,
        #7375e2 100%
      );
    }
  }
}
.dashboard-top10-coupon-acquired-card .dashboard-card-data-total {
  font-family: Mulish;
  font-style: normal;
  color: #979797;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  .dashboard-card-data-total-line {
    display: block;
  }
  .dashboard-card-data-number {
    font-weight: 800;
    margin-left: 4px;
  }
}
