.reference-prompt-container-bottom {
  margin-top: 0;
}

.reference-prompt-container {
  padding: 37px 39px 27px;
}

.reference-prompt-other-body-container {
  margin-top: 13px !important;
}

.reference-prompt-description {
  margin-top: 23px !important;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #4d5560;
}
