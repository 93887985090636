div.custom-data-table {
  //   background: #fff;
  margin: 0px 40px 40px 40px;

  //   box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  margin: 0px 40px 40px 40px;
  width: auto;
  min-height: 0px;
  //   padding: 22px 33px;
  margin-top: 34px;
}

div.custom-data-table > div.head {
  padding: 0px 33px;
  display: flex;

  div.buttons {
    display: flex;
    flex-direction: row;
    margin-left: 26px;
    margin-top: auto;
    margin-bottom: auto;

    div.dropdown {
      align-self: center;

      button {
        font-size: 14px;
        font-weight: 400;

        &:disabled {
          opacity: 0.5;
        }
      }

      div.custom-dropdown-menu {
        z-index: 3;
      }
    }
  }
}

th.mutiple-head > div {
  display: flex;
  flex-direction: column;

  padding: 24px 0px !important;
}

.custom-data-table
  > div.overview-list-section.common-table-overflow
  > table
  > tbody
  > tr
  > td {
  text-align: center;
  padding: 13px 5px;
}
.custom-data-table
  > div.overview-list-section.common-table-overflow
  > table
  > tbody
  > tr
  > td.customer-overview-header-th
  div {
  justify-content: center;
  width: 100%;
}

.custom-data-table
  > div.overview-list-section.common-table-overflow
  > table
  > thead
  > tr
  > th
  > div
  label {
  width: 100%;
}

// .overview-all-list-table thead tr th div {
//   display: flex;
// }

.data-table-caution-area {
  margin-bottom: 8px;
  margin-top: 30px;
}

.letter-uppercase {
  text-transform: uppercase;
}

.data-table-caution-content {
  flex-direction: column;
  white-space: pre-line;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.data-table-caution-container {
  max-width: 477px;
  width: 477px;
  ul {
    margin-left: 0;
    width: 100%;
    list-style-position: inside;
    padding-left: 0;
  }
  li span {
    position: relative;
    left: -8px;
  }
}

.custom-button {
  position: absolute;
  right: 68px;
}
