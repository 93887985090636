.batch-manager {
  // margin-right: 12px;

  .dropdown {
    z-index: 3;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 25px;

      border: 2px solid #4e8dd7;
      box-sizing: border-box;
      border-radius: 100px;

      background: #fff;

      font-family: 'Mulish';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      text-align: right;

      color: #4e8dd7;

      outline: none;
    }

    // .show > .btn-primary.dropdown-toggle:focus,
    // button:visited,
    // button:focus,
    // button:active {
    //   outline: none;
    //   color: #4e8dd7;
    //   background: #fff;
    //   box-shadow: none;
    //   border: 2px solid #4e8dd7;
    // }
  }
}

// .show > .btn-primary.dropdown-toggle {
//   outline: none;
//   color: #4e8dd7;
//   background: #fff;
//   box-shadow: none;
// }

.custom-dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 25px;

  border: 2px solid #4e8dd7;
  box-sizing: border-box;
  border-radius: 100px;

  background: #fff;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: right;

  color: #4e8dd7;

  outline: none;

  .toogle-area-indicator {
    width: 16px;
    height: 16px;
    object-fit: scale-down;
    margin-left: 8px;
    margin-top: 4px;
    align-self: center;
    transition: all 0.5s ease;
  }
}

.custom-dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.hide-after:after {
  display: none;
}

.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:active,
.custom-dropdown-toggle:visited,
.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:not(:disabled):not(.disabled):active {
  outline: none;
  color: #4e8dd7;
  background: #fff;
  box-shadow: none;
  border: 2px solid #4e8dd7;
}

.custom-dropdown-toggle:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.custom-dropdown-menu {
  margin-top: 10px;

  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;

  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  width: fit-content;
  flex-direction: column;
}

.custom-dropdown-item {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-transform: capitalize;
  display: block;
  white-space: nowrap;

  color: #404040;
  margin-bottom: 16px;
}

.custom-dropdown-item:hover {
  color: #4e8dd7;
}
