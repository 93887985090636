.check-otp-search-button {
  width: 100%;
  margin-top: 24px;
}
.check-otp-mobile-number-container {
  position: relative;
}

.check-otp-customer-label {
  margin-top: 8px;
  color: #4D5560;
  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: break-spaces;
}
.check-otp-customer-label.registered {
  color: #F00;
}
