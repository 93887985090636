.all-list-table-content {
  margin-right: 0px;
}
.base-simple-table-div {
  width: 100%;
  overflow-x: auto;

  .base-simple-table {
    position: relative;
    border-color: #dadada;
    margin-top: 16px;
    min-width: 100%;
    max-width: 100%;
    width: unset;
    table-layout: fixed;
    border: 0;
    thead {
      width: 100%;
    }
    thead > tr > td {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #4D5560;
    }
  
    tbody > tr > td {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  
    td {
      font-family: 'Mulish';
      font-style: normal;
      max-height: 68px;
      padding: 24px 16px 24px 16px;
      word-wrap: break-word;
      label {
        word-wrap: break-word;
      }
    }
  
    .simple-list-status-field {
      // text-align: center;
    }
    .simple-list-action-field {
      div:first-child {
        text-align: center;
      }
    }
  }
}
