.side-bar-logo {
  height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  object-fit: contain;
}

.side-menu-nav-background {
  background-color: transparent;
}

.side-close-btn {
  width: 24px;
  height: 24px;
  align-self: center;
  cursor: pointer;
}
