.table{
  margin-top: 30px;
}

.table th,
.table td {
  text-align: center;
}
.th{
  font-family: Mulish;
  font-size: 16px;
}
.td{
  font-size: 14px;
}
.campaign-section-field-clumn{
  font-size:16px;
  font-weight: 800;
  color: #404040;
}

.campaign-section-field-input-id{
  font-size:16px;
  font-weight: 800;
  color: #404040;
}
.campaign-section-view-all-campaigns{
  text-align: center;
}
.campaign-section-view-all-campaigns-button{
  background-color: #4e8dd7;
  color: #ffffff
}