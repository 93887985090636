.offer-type-labels-content {
    margin-bottom: 0;
}

.offer-type-labels-related-campaign {
    margin-top: 10px;
}

.offer-type-labels-content-sections {
    margin-bottom: 0;
}