
.second-section_description {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-top: 4px;
}

.multiple-color-input-row {
    display: flex;
    flex-direction: row;
  
    input.color-input-field {
        width: 95px !important;
        border-radius: 4px;
        border: 1px solid #C2C2C2;

        &:disabled {
          background: #F7F7F7;
        }
    }
  }