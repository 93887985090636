.overview-customer-card {
  height: 341px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  margin: 0px 40px 40px 40px;
  padding-top: 30px;
}
.overview-customer-card-title {
  margin-left: 30px;
  margin-bottom: 20px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #979797;
}
