.goodie-bag-step-one-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin-bottom: 10px;
}

.goodiebag-slot-delete-icon {
  margin-left: auto;
}


.reminder-prompt {
  .base-prompt-header {
    font-weight: 800;
  }

  .base-prompt-description {
    font-weight: 700;
    margin-top: 16px;

  }

  .base-prompt-other-body {
    max-height: 300px;
    overflow-y: auto;
  }

  li {
    font-family: Mulish;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    max-width: 673px;
    word-wrap: break-word;
  }
}

.goodie-bag-set-landing-button {
  margin-top: 7px;
  margin-left: 15px;

  label {
    margin-bottom: 0px;
  }

  label:nth-of-type(2) {
    margin-left: 5px;
    font-family: Mulish;
  }
}