.terms-and-conditions-confirm-to-save {
    max-height: 500px;
    overflow: auto;
}

.terms-and-conditions-confirm-to-save li {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 16px;
}
