.empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-container-image {
  width: 249.28px;
  height: 192px;
  object-fit: contain;
  align-self: center;
}
