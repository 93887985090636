.create-goodie-bag-input {
  max-width: 700px;
}

.create-goodie-bag-step-two {

  .custom-markdown-area-title {
    width: 100%;
    max-width: 700px;
  }

  label.upload-file-name {
    margin-bottom: 0px;
  }

  div.campaign-devide-line {
    margin-top: 0px;
  }
}

.multiple-input-row {
  display: flex;
  flex-direction: row;

  label {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 8px;

    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    color: #404040;
  }

  input {
    width: 30% !important;
    border-radius: 4px;
    border: 1px solid #C2C2C2;


    &:disabled {
      background: #F7F7F7;
    }
  }
}

.font16-blod {
  font-size: 16px !important;
  font-weight: 800;

  margin-top: 20px !important;
}

.tip2 {
  white-space: break-spaces;
  line-height: 100%;
  font-size: 14px !important;
}


.slot-name-setting {
  display: flex;
  flex-direction: row;

  div {
    width: 100%;
  }

  label.create-section-label-bottom-space.landing {
    position: absolute;
    margin-left: 643px;

    font-family: Mulish;
    font-size: 14px;
    font-weight: 800;
    line-height: 17.57px;
    text-align: center;

    color: #979797;
  }

  button,
  button:focus {
    position: absolute;
    margin-left: 600px;
    margin-top: 20px;
    height: 34px;
    text-align: center;
    padding: 0px 25px;
  }
}

.slot-label-title {
  margin-top: 20px;
}

.slot-dotted-line {
  margin: 30px 0 0;
  width: 100%;
  max-width: 700px;
  height: 0px;

  border: none;
  border-top: 1px dashed #DADADA;
  color: #fff;
  background-color: #fff;
  
}
