.entitlement-info-area {
  padding: 0;
  .list-section-container-button-and-title {
    height: 40px;
  }

  .all-list-component-action-header-div {
    padding: 16px 0;
    label {
      margin: 0;
    }
    label:first-child {
      font-family: 'Mulish';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      align-self: center;
      color: #404040;
    }
  }

  .goodie-bag-mini-program {
    margin: 0;
    padding: 10px 0;
    vertical-align: middle;
    height: 46px;
    button {
      padding: 0;
    }
  }

  .all-list-filter-container {
    right: 292px;
    top: 143px
  }
  .all-list-search-container {
    right: 0;
    top: 136px;
  }
}

.entitlement-simple-table {
  td:not(:first-child) {
    text-align: center;
  }
}