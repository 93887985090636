.custom-step-content {
  margin-bottom: 50px;
}

.start-head {
  margin-top: 0px;
}

.display-as-block {
  display: block;
}

.image-container {
  padding: 16px;
  max-width: 800px;
  border: 1px solid #DADADA;
  border-radius: 10px;

  .image-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .create-section-label-bottom-space {
    margin-top: 0px;
  }
  .create-section-label {
    font-weight: 600;
  }
}
.image-container.with-side {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 30px;
  .side-default-image {
    width: 139px;
    height: 104.5px;
  }

  .title-side-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.57px;
    text-align: center;
    color: #4E8DD7;
    cursor: pointer;
  }
}
.image-container .title-container.with-side {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.title-container.with-side {
  align-items: center;
  margin-bottom: 8px;
}

.reward-information-container {
  .image-container {
    margin-top: 30px;
  }
}

.other-information-container {
  .image-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 30px;
    .side-default-image {
      width: 139px;
      height: 104.5px;
    }
  }
  .image-container .title-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  .title-container {
    align-items: center;
    margin-bottom: 8px;
  }
}

.cover-images-for-list,
.cover-images-for-task-list,
.cover-images-for-detail-photos,
// .cover-images-for-prize-list,
.cover-images-for-mission-section {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  max-width: 800px;
}

.cover-images-for-list,
.cover-images-for-task-list,
.cover-images-for-detail-photos,
.cover-images-for-mission-section {
  .image-container {
    max-width: 392px;
    
    .image-content {
      height: 100%;
    }
  }
}

.tutorial-container .image-container {
// .cover-images-for-prize-list,
// .cover-images-for-mission-section {
  max-width: 392px;
}

.cover-images-for-mission-section {
  .image-container.with-side {
    margin-top: 0px;
  }
}

.cover-images-for-stamp-icons,
.cover-images-for-reward-icons {
  display: flex;
  gap: 16px;
  max-width: 800px;

  .image-container {
    max-width: 392px;
  }
  .title-side-default-image-container {
    .text {
      width: fit-content;
      padding: 2.73px 5.45px;
      border-radius: 68.18px;
      background: #979797;
      position: absolute;

      font-family: Open Sans;
      font-size: 6.82px;
      font-weight: 600;
      line-height: 9px;
      color: #FFFFFF;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.tutorial-item-container,
.reward-information-item-container,
.other-information-item-container,
.prize-category-container {
  position: relative;
  .delete-icon {
    position: absolute;
    left: 667px;
    img {
      width: 33px;
      height: 33px;
    }
  }
}

.reward-information-item-container, .other-information-item-container {
  .delete-icon {
    left: 667px;
    top: 30px;
  }
}

.prize-category-container {
  .delete-icon {
    left: 632px;
    top: 60px;
  }
}


.stamp-list-container {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .stamp-item-container {
    width: 88px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .index-content {
      width: 25px;
      height: 25px;
      border-radius: 100px;
      background: #4E8DD7;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;

      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      color: #FFFFFF;
    }
    .edit-icon, .delete-icon {
      position: absolute;
      margin: 0px;
      padding: 0px;
      img {
        width: 33px;
        height: 33px;
      }
    }
    .edit-icon {
      top: 0px;
      right: 0px;
    }
    .delete-icon {
      right: 0px;
      bottom: 0px;
    }
    .stamp-icon {
      width: 77px;
      height: 77px;
    }
  }
}

.stamp-reward-item-container {
  .sortable-left {
    gap: 16px;
  }
}

.reward-section-fields-container input,
.mission-section-fields-container input {
  margin-top: 8px;
}

// preview / detail
.cover-images-row {
  display: flex;
  gap: 30px;
  .cover-image-item {
    display: flex;
    flex-direction: column;
    gap: 0px;
    min-width: 155px;
    img {
      width: 150px;
      height: 85px;
    }
    label {
      margin-bottom: 12px;
    }
  }
}
.cover-images-row.icon-images {
  img {
    width: 85px;
    height: 85px;
  }
}
.cover-images-double-title-container .cover-image-item label {
  margin-top: 0px;
  margin-bottom: 12px;
}

.preview-stamp-reward-container {
  ul {
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    label:first-child {
      margin-bottom: 12px;
    }
  }
  
  .create-section-label-bottom-space  {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

// edit reward slot prompt
.cover-images-for-stamp-reward-icons {
  .image-container {
    margin-top: 30px;
    padding: 0px;
    border: 0px ;
    border-radius: 0px;
  }
}

.edit-stamp-reward-prompt {
  .scroll-container-common {
    padding: 0px;
  }
  .create-section-and-preview-container {
    margin-bottom: 0px;
  }
  .language-tab-container {
    box-shadow: none;
    border: 1px solid #C2C2C2;
    border-bottom: 1px solid #dadada;
  }
  .create-step-section-item-first-section {
    box-shadow: none;
    border: 1px solid #C2C2C2;
    border-top: 0px;
  }
  .custom-textarea-remakrs {
    width: 100%;
    max-width: 700px;
    height: 187px;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    padding: 8px;
  }
  .prize-category-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .create-section-label-bottom-space {
      margin: 0;
    }
    input {
      width: 100%;
      max-width: 640px;
    }
    .delete-icon {
      position: unset;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .delete-icon.hidden {
      visibility: hidden;
    }
  }
  .prize-category-container:nth-child(n+1) {
    margin-top: 12px;
  }
  .cover-images-for-prize-list {
    .image-container {
      border: 0px;
      padding: 0px;

      .create-section-label {
        font-weight: 800;
        margin-top: 30px;
      }
    }
  }
  .base-prompt-buttons {
    margin: 0px;
    padding: 0px;
  }
}


.mission-section-image-reference-prompt {
  width: 800px;
  padding: 30px;

  .base-prompt-other-body {
    margin-top: 30px;
  }

  .scroll-container-common {
    padding: 0px;
  }
  .create-section-and-preview-container {
    margin: 0px;
  }
  .language-tab-container {
    box-shadow: none;
    border-bottom: 1px solid #dadada;

    a.nav-item.nav-link:nth-child(1) {
      margin-left: 0px;
    }
  }
  .create-step-section-item-first-section {
    box-shadow: none;
    border: 0px;
    margin: 0px;
    padding: 0px;
    margin-top: 30px;
  }

  .buttons-container {
    margin-bottom: 16px;
    display: flex;
    gap: 16px;

    button {
      padding: 6px 20px;
      width: fit-content;
      height: auto;
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 17.57px;
    }
    button.btn-further {

    }
    button.btn-back-button-common {
      color: #8C8C8C;
      border: 2px solid #8C8C8C;
    }
    button.btn-back-button-common:hover {
      // background-color: unset;
    }
  }
  
  .mission-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .mission-card-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #C2C2C2;

      .card-head-container {
        padding-bottom: 16px;
        border-bottom: 1px solid #C2C2C2;
        .title {
          font-family: Mulish;
          font-size: 20.24px;
          font-weight: 900;
          line-height: 25.41px;
          color: #002148;
          text-transform: uppercase;
        }
        ul {
          list-style-type: disc;
          padding-inline-start: 20px;
        }
      }
    }
  }

  .base-prompt-buttons  {
    margin: 0px;
    padding: 0px;
  }
}


