@import '../../utils/Variables.scss';
.dashboard-compare-date {
  display: inline-block;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $deepGrey;
  margin-bottom: 0px;
  max-width: 219px;
}
.customer-list-compare-list {
  align-self: center;
  margin-left: 18px;
}
