.dashboard-customer-gender-card {
    min-width: 465px;
    height: 567px;
    font-family: Mulish;
    font-style: normal;
    .dashboard-card-title {
        margin-top: 30px;
        margin-left: 30px;
        line-height: 22.5px;
    }
    .dashboard-card-body {
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .dashboard-card-data {
        position: relative;
        height: 265px;
        width: 10px;
        margin: auto;
    }
    .dashboard-customer-male {
        .custom-tips-area {
            position: absolute;
            top: 75px;
            left: 65px;
        }
    }
    .dashboard-customer-male-title-name {
        color: #979797;
        font-size: 16px;
        font-weight: 600;
    }
    .dashboard-customer-male-title-icon {
        margin-top: 5px;
        margin-left: 2px;
        width: 16px;
        height: 16px;
    }
    .dashboard-customer-male-number {
        position: absolute;
        top: 110px;
        left: 65px;
        font-size: 24px;
        font-weight: 800;
        line-height: 20px;
        color: #000000;
    }
    .dashboard-customer-male-specific-number {
        position: absolute;
        top: 140px;
        left: 65px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #000000;
    }
    .dashboard-customer-female {
        .custom-tips-area {
            position: absolute;
            top: 225px;
            left: 65px;
        }
    }
    .dashboard-customer-female-title-name {
        color: #979797;
        font-size: 16px;
        font-weight: 600;
    }
    .dashboard-customer-female-title-icon {
        margin-top: 5px;
        margin-left: 2px;
        width: 16px;
        height: 16px;
    }
    .dashboard-customer-female-number {
        position: absolute;
        top: 260px;
        left: 65px;
        font-size: 24px;
        font-weight: 800;
        line-height: 20px;
        color: #000000;
    }
    .dashboard-customer-female-specific-number {
        position: absolute;
        top: 290px;
        left: 65px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #000000;
    }
    .dashboard-customer-gender-image {
        position: absolute;
        width: 50px;
        height: 92px;
    }
    .dashboard-customer-gender-image-half {
        position: absolute;
        width: 25px;
        height: 92px;
    }
    .dashboard-card-data-defined {
        color: #979797;
        position: absolute;
        left: 30px;
        bottom: 30px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        .dashboard-card-data-collected {
            display: block;
        }
        .dashboard-card-data-undefined {
            display: block;
        }
        .dashboard-card-data-number {
            font-weight: 800;
            margin-left: 4px;
        }
    }
}

.dashboard-customer-gender-card .dashboard-card-title .dashboard-customer-gender-label-caution {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #979797;
    text-transform: none;
}

.dashboard-and-overview-as-of-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #979797;
    text-transform: none;
}