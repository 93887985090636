.coupon-download-table-overflow {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  width: auto;
  min-height: 0px;
  padding: 22px 33px;
}

.coupon-download-table-header {
  padding: 0px 33px;
  display: flex;
  margin-bottom: 30px;
  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: 26px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.coupon-download-list-tab-container {
  height: 70px;
  background-color: white;
  border-bottom: 1px solid #dadada;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
  // min-width: 1186px;
  width: 100%;
  &.hide-tab-bar a {
    visibility: hidden;
  }
}
.coupon-download-list-filter-container {
  right: 66.46px;
  top: 61px;
  width: auto;
  position: absolute;
  display: flex;
}

.coupon-download-list-rank-container {
  display: flex;
  flex-direction: row;
  > .custom-editable-rank {
    padding-right: 1px;
  }
}

.coupon-download-list-table {
  border-collapse: collapse;
  width: 100%;
}

.coupon-download-list-table tr th {
  position: sticky;
  top: 0px;
  background: white;
  box-shadow: inset 0 -1px 0 #c2c2c2;
}

.coupon-download-list-table tr th,
.coupon-download-list-table td {
  // border-bottom: 1.5px solid #dadada;
  padding: 0px 0px;
}

.coupon-download-list-table td {
  border-bottom: 0.5px solid #dadada;
}
.coupon-download-list-table td:nth-child(1),
.coupon-download-list-table th:nth-child(1) {
  width: 52px;
}

// .coupon-download-list-table th:last-child {
//   position: sticky;
//   right: 0;
//   z-index: 1;
//   text-align: center;
// }

// .coupon-download-list-table td:last-child {
//   position: sticky;
//   right: 0;
//   box-shadow: inset 0px -1px 0px #dadada;
//   border-bottom: unset;
//   text-align: center;
// }

.coupon-download-list-table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
}

.coupon-download-list-table th:nth-child(2) {
  position: sticky;
  left: 122px;
  z-index: 1;
}

.coupon-download-list-table th:nth-child(3) {
  position: sticky;
  left: 244px;
  z-index: 1;
  box-shadow: inset 0px -1px 0px #dadada;
}

.coupon-download-list-table td:nth-child(1) {
  position: sticky;
  left: 0;
}

.coupon-download-list-table td:nth-child(2) {
  position: sticky;
  left: 122px;
}

.coupon-download-list-table td:nth-child(3) {
  position: sticky;
  left: 244px;
}

.coupon-download-list-table tbody tr:nth-child(even) td {
  background-color: white;
}

.coupon-download-list-table tbody tr:nth-child(odd) td {
  background: #f7faff;
}

.coupon-download-table-shadow-right {
  width: 0;
  position: absolute;
  top: 0;
  height: 630px;
  right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  background: transparent;
  pointer-events: none;
}

.coupon-download-list-refresh-tips {
  white-space: pre-line;
  margin-left: 8px;
}

.filter-right-more {
  right: 300px;
}

.coupon-download-show-count {
  padding: 0 40px;
  float: right;
}

.coupon-download-list-loading-status {
  color: #e2002c;
  padding-right: 8px;
}

.coupon-download-list-loaded-status {
  color: #04bd81;
  padding-right: 8px;
}
