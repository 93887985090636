.custom-base-input {
    background: white;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding-left: 12px;
    width: 100%;
  }
  
  .country-code-input {
    padding-left: 25px;
  }
  
  .plus-icon {
    position: absolute;
    top: 7px;
    left: 12px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  
  .custom-delete-icon {
    position: absolute;
    top: calc((40px - 27px) / 2);
    right: 12px;
    width: 27px;
    height: 27px;
    object-fit: contain;
  }
  
  .ant-select-dropdown {
    border-radius: 8px;
  }
  