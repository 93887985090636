@import "../../../utils/Variables.scss";
.display-over-all-limit-container {
  display: flex;
  flex-direction: row;
}
.display-over-all-limit {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
}

.display-over-all-limit-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.profile-section-label-bottom {
  margin-bottom: 0px;
}

.create-section-label.create-section-label-bottom-space.campaign-per-head-limit {
  margin-bottom: -20px;
}

.create-section-label.create-section-label-bottom-space.campaign-per-head-coupon {
  font-weight: bold;
}

.campaign-number-of-coupon {
  white-space: pre-line;
}

.view-detail-of-channel-limit {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4e8dd7;
  cursor: pointer;
}

.base-prompt-container.channel-limit-prompt-container {
  width: 725px;
}

.channel-limit-prompt {
  display: flex;
  flex-direction: column;
}

.channel-limit-prompt .channel-limit-prompt-total-data {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin: 8px 0px;
}

.channel-limit-prompt .channel-limit-prompt-channel-title {
  background: #e3e3e3;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 23px;
  max-width: 805px;
  height: 58px;
  margin-top: 20px;
}

.channel-limit-prompt .channel-limit-prompt-channel-area {
  padding: 0 15px;
}

.channel-limit-prompt .channel-limit-prompt-market-area {
  padding: 0 15px;
}

.channel-limit-prompt .channel-limit-prompt-content {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin: 4px 0px;
}

.channel-limit-prompt .channel-limit-prompt-check-hook {
  width: 13px;
  height: 10px;
  margin-right: 7px;
}

.channel-limit-prompt .channel-limit-prompt-market-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin: 4px 0px;
}

.channel-limit-prompt .channel-limit-prompt-tips-messages {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8c8c8c;
}

.channel-limit-prompt .channel-limit-prompt-unLimited {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin: 12px 0px;
}

.channel-limit-prompt .channel-limit-prompt-unLimited-channel {
  margin: 10px 0 5px 0;
}
