@import '../../utils/Variables.scss';

.custom-markdown-area-title {
  background: white;
  border: 1px solid $lightGreyTwo;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
  max-width: 700px;
}

.custom-markdown-area-title:disabled {
  background: #f7f7f7;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-markdown-area-title-short {
  max-width: 619px;
}

.custom-markdown-area-title:active,
.custom-markdown-area-title:focus {
  border: 1px solid #2b84ee;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  outline: 0px;
}

.html-viewer {
  p {
    margin-bottom: 0px;
  }
}

.short-description-html-viewer{
  word-wrap: break-word;
  max-width: 100%;
  p {
    margin-bottom: 0px;
  }
  a{
    color: #000;
  }
  a:hover {
    color: #1890ff;
  }
}