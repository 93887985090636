.check-member-card-container {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(172, 192, 215, 0.34);
  border-radius: 8px;
  width: calc(100% - 16px - 14px);
  margin: 16px;
  margin-right: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.check-member-title-container {
  display: flex;
  flex-direction: row;
  height: 20px;

  .check-member-title-date {
    position: absolute;
    right: 15px;
    margin-top: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #404040;
  }
}
.check-member-title-devide-line {
  margin-left: 15px;
  margin-top: 8px;
  border: 0.5px solid #e9e9e9;
  width: calc(100% - 15px - 15px);
}

.check-member-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 14px;
  width: calc(100% - 15px - 15px);
  margin: 10px 15px 20px;

  .check-member-detail-link a{
    color: #1890ff;
  }
}

.check-member-content-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
  color: #404040;
  > div {
    max-width: 100%;
    word-wrap: break-word;
  }
}

.check-member-record-loading {
  height: 100%;
  background-color: inherit;
}
