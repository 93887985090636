label {
  margin-bottom: auto;
}

.caution-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  object-fit: contain;
  align-self: center;
}

.caution-container {
  background: rgba(0, 33, 72, 0.9);
  border-radius: 8px;
  width: 300px;
}

.caution-content {
  padding: 18px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.bs-popover-auto[x-placement^='right'] > .arrow:after,
.bs-popover-right > .arrow:after {
  left: 3px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 33, 72, 0.9);
}

.tips-pointer-container {
  display: flex;
  overflow-wrap: break-word;
  margin-right: 15px;
}

.custom-tips-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.caution-content-image {
  padding: 18px 0px;
}

.custom-tips-area-image-container {
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 270px;
}

.caution-container-width-with-image {
  width: auto;
}

.custom-tips-area-image-content {
  border: 1px solid white;
  width: 215px;
  height: auto;
}

.custom-tips-area-image-label {
  width: 249px;
  margin-bottom: 10px;
  align-self: center;
}
