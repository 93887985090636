.single-coupon-overview-general {
  padding: 30px;
  margin-bottom: 30px;
}
.single-coupon-overview-usage {
  padding: 30px;
  margin-bottom: 108px;
}
.single-coupon-overview-coupon-qr-code {
  margin-top: 12px;
}

.week-rule-area {
  margin: 8px 0px;
}