.base-prompt-container.result-prompt-container {
  padding: 0 0 32px 0;
  text-align: center;
}
.base-prompt-container.confirm-prompt {
  min-width: 800px;
}
@media (max-width: 800px) {
  .base-prompt-container.confirm-prompt {
    min-width: auto;
    text-align: center;
  }
  .confirm-prompt .base-prompt-close-button {
    display: none;
  }
  .confirm-prompt .base-prompt-header {
    align-self: auto;
  }
  .confirm-prompt .base-prompt-buttons {
    display: block;
    position: relative;
    height: 90px;
  }
  .confirm-prompt .base-prompt-buttons button {
    width: 80%;
  }
  .confirm-prompt .base-buttons-secondary {
    position: absolute;
    top: 56px;
  }
}

.result-button {
  width: 70%;
  margin: auto !important; 
}
.result-title-area {
  padding: 16px;
}
.result-title-area.success {
  background-color: #04BD81;
}
.result-title-area.failed {
  background-color: #E2002C;
}
.result-title-area.redeemed {
  background-color: #F29B19;
}
.result-title-area .base-prompt-header {
  color: white;
}
.result-title-area .item-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;
}
.result-title-area .result-image {
  margin: 16px auto;
}
.result-body-area {
  width: 80%;
  margin: 0px auto 16px;
}
.result-body-area .item {
  padding: 8px;
}
.prompt-body-area .item {
  padding: 8px 0px;
}
.result-body-area .item-title, .prompt-body-area .item-title{
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
}

.result-body-area .item-errors {
  background-color: #F7F7F7;
  margin-top: 16px;
  padding: 10px;
  border-radius: 8px;
}