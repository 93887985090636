
.manage-search-list-list-table {
  .all-list-scrollable-area-name {
    width: 262px;
  }

  // .all-list-scrollable-area-actions {
  //   z-index: 5;
  // }

  th:nth-child(3) {
    z-index: 6;
  }
  .all-list-scrollable-area-actions {
    z-index: 5;
  }
  .all-list-scrollable-area-empty-column {
    width: 12px;
    min-width: 12px;
  }
}
