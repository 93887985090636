.manual-use-coupon{
  width: 370px;
}

.manual-use-coupon-hint{
  margin-top: 16px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // line-height: 20px;
  display: flex;
  align-items: center;
  color: #404042;
}

.input-srks{
  flex-wrap: 'wrap';
  display: flex;
  flex-direction: 'row';
}


.srk-connector{
  width: 16px;
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  align-self: center;
  color: #000000;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 45px;
  margin-bottom: 0px;
}

.srk-input-text {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  margin-top: 8px;
}

.srk-input-text[type="text"]{
  padding-left: 8px;
}

.srk-input-text:focus {
  border: 1px solid #c2c2c2;
  outline: none;
}

.srk-input-text-error {
  background: #ffffff;
  border: 1px solid red;
  box-sizing: border-box;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  margin-top: 8px;
}

.srk-input-text-error:focus {
  border: 1px solid red;
  outline: none;
}

.mamual-use-coupon-error{
  color: red;
  margin-top: 8px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 14px;
}

.manual-use-coupon-dropdown{
  background-color: #F7F7F7;
  pointer-events: none;
  cursor: default;
}

.second-section-title-quote{
  color:#979797;
  display: inline-block;
  margin: 0;
}

.use-coupon-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.use-coupon-button, .use-coupon-button:focus{
  color: #ffffff;
  background-color: #4e8dd7;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  box-shadow: none;
  padding: 7px 25px;
  height: 40px;
  border-radius: 100px;
  margin-right: 20px;
  margin-left: auto;
  align-self: center;
  text-align: center;
}

.use-coupon-button:disabled, .use-coupon-button.disabled{
  background-color: #4e8dd7;
}

@media (max-width: 768px) {
  .srk-connector{
    width: 16px;
    display: flex;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    align-self: center;
    color: #000000;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 0px;
  }

  .manual-use-coupon, 
  .srk-input-text, 
  .srk-input-text-error, 
  .srk-input-text-error:focus{
    width: 100%;
  }

  .use-coupon-button, .use-coupon-button:focus{
    color: #ffffff;
    background-color: #4e8dd7;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    box-shadow: none;
    padding: 7px 25px;
    height: 40px;
    border-radius: 100px;
    margin-right: auto;
    margin-left: auto;
    align-self: center;
    text-align: center;
    width: 326px;
  }
}