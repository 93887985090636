$image-size: 80px;
.display-qr-code {
  background: #ffffff;
  // border: 0.749228px solid #c2c2c2;
  // border-radius: 5.99382px;
  // padding: calc((75px - 2px - 60px) / 2);
  width: $image-size;
  height: $image-size;
}

.qr-code-container,
.qr-code-container:hover {
  cursor: pointer;
  display: flex;
  // width: 75px;
  flex-direction: row;
  position: inherit !important;
  .hover-qr-code-container {
    width: 232px;
    height: 294px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .hover-qr-code-title {
      height: 16px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #4d5560;
      margin: 24px 0 0 0;
    }
    .qr-code-download-image {
      margin: 16px 36px;
      background: #ffffff;
      background: #ffffff;
      border: 0.749228px solid #c2c2c2;
      border-radius: 5.99382px;
      padding: 10px;
    }
  }

  .qr-code-download-button {
    padding: 0;
    margin: 0 36px 23px;
    width: 160px;
    height: 40px;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}
