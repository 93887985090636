.day-input-fields {
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 12px;
  margin-right: 10px;
  height: 40px;
  width: 100px;

  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  &:focus,
  &:active {
    box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
    border: 1px solid #2b84ee;
    outline: 0px;
  }
}
.days-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #404042;
}

.muiltpleLinesInput {
  height: 100px;
}

.redeemPeriodArea {
  display: flex;
  
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  border-radius: 10px;
  border: 1px solid #C2C2C2;

  .period-item {
    width: 100%;

    .period-item-title {
      display: flex;

      label {
        color: #4D5560;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .period-item-title-buttons {  
        display: flex;
        margin-left: auto;

        img {
          width: 24px;
          height: 24px;
        }

        div {
          display: flex;
        }

        .period-item-title-buttons-delete {
          cursor: pointer;
          label {
            margin-left: 4px;
            color: #C2C2C2; 
          }
        }

        .period-item-title-buttons-edit {
          cursor: pointer;
          margin-left: 20px;
          label {
            margin-left: 4px;
            color: #4E8DD7;
          }
        }
      }
    }

    .period-item-times {
      margin-top: 10px;

      color: #4D5560;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; 

      li span{
        margin-left: -10px;
      }

      // li::marker {
      //   position: relative;
      //   left: -10px;
      //   padding-inline-start: 1ch;
      // }
    }
  }
}

.left-button,
.left-button:focus {
  margin-left: 0px;
  margin-right: auto;
}


.sperate-line2 {
  width: 100%;
  border: 1px dashed #C2C2C2;
}

.edit-period-area {
  min-width: 850px;
  max-height: 900px;
  overflow-y: auto;
}

.one-time-period {
  display: flex;

  .period-item-title-buttons-delete {
    margin: 20px 0px 8px auto;
    cursor: pointer;
    display: flex;
    label {
      cursor: pointer;
      margin-left: 4px;
      color: #C2C2C2; 
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.edit-time-period {
  display: flex;
  flex-direction: row;
}

.sperate {
  margin: 60px 16px 9px;
}

.title-space {
  margin: 20px 0px 8px;
}

.redemption-period {
  border-top: 1px dashed #C2C2C2;;
  
  margin-top: 20px;

  div.tips-pointer-container {
    margin-bottom: 8px;

    .tips-message {
      margin-bottom: 0px;
    }
  }
}

.redemption-period-prompt-buttons {
  border-top: 2px solid #C2C2C2;
}

.switch-tips {
  white-space: break-spaces;
}