.manage-recaptcha-container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
  width: calc(100% - 40px - 40px);
  margin: 30px auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.manage-recaptch-title {
  margin: 0;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #404040;
}

.manage-recaptch-tips {
  margin: 8px 0;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #8c8c8c;
}
