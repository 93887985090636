@import '../../utils/Variables.scss';
.dashboard-header {
  padding-bottom: 10px !important;
}
.dashboard-campaigns-coupons {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 32px;
  display: inline-block;
}
.dashboard-card-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  // margin-left: 30px;
  margin-top: 30px;
  color: #002148;
}
.dashboard-overview-link {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #4e8dd7;
  float: right;
}
.dashboard-overview-link-icon {
  margin-left: 10px;
}
.dashboard-header-left {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-title {
  display: inline-block;
  margin-right: 15px;
}

.progress {
  margin: 10px 0px;
  height: 24px;
  border-radius: 12px;
  background-color: #eefcff;
  margin-bottom: 26px;
}

.progress-bar {
  border-radius: 12px;
  padding-left: 10px;
  padding-top: 2px;
  text-align: left;
  font-weight: bold;

  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;
}

.top3 .progress-bar {
  background-image: linear-gradient(180deg, #ea5c7a 0%, #f4a172 100%);
  // animation: progres 4s ease-in-out;
  // transition: width 5s ease-in-out;
  // transition: 'width 1s ',
}

.others .progress-bar {
  background-image: linear-gradient(
    180deg,
    #00a9c8 0%,
    #5383db 42.19%,
    #7375e2 100%
  );
  // animation: progres 4s infinite linear;
  // transition: 2s ease;
  // transition-delay: 0.5s;
}

.non-progress .progress-bar {
  background-image: linear-gradient(180deg, #eefcff 0%, #eefcff 100%);
}

.progress-bar .dashboard-card-data-percentage {
  position: absolute;
}

.display-flex-right {
  margin-left: auto;
}

.starred-campaign-filter {
  align-items: center;
  padding: 10px 0px 12px 0px;

  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .dashboard-campaign-selector {
    max-width: 60%;
  }

  .dashboard-campaign-selector > button {
    display: flex;
    align-items: center;

    background-color: #f5f5f5;
    min-width: 120px;
    max-width: 100%;
    height: 36px;
    padding: 8px 30px 8px 15px;
    border: none;

    label {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: left;

      color: #404040;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      // margin-left: auto;
      position: absolute;
      right: 40px;
    }

    &:active,
    &:focus,
    &:visited {
      color: #404040;
      background-color: #f5f5f5;
      border-color: transparent;

      box-shadow: none;
    }
  }

  .btn-primary {
    color: #404040;
    background-color: #f5f5f5;
    border-color: transparent;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #404040;
    background-color: #f5f5f5;
    border-color: transparent;

    box-shadow: none;
  }

  div.dropdown-menu {
    padding: 10px 0 20px 0;

    // position: absolute;
    // height: 202px;
    // left: 52.25%;
    // right: 41.08%;
    // top: calc(50% - 202px / 2 + 460px);

    background: #ffffff;
    /* Grey */

    border: 1px solid #cccccc;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);

    a {
      padding: 8px 10px 8px 16px;

      &:hover {
        background: #f5f5f5;
      }

      label {
        line-height: 18px;
      }

      .checkbox-container {
        margin-top: 0px;
      }
    }
  }

  label.platform-checkbox {
    font-size: 14px;
  }

  .checkmark {
    border-color: #c2c2c2;
  }
}

@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
}

.dashboard-error {
  position: absolute;
  left: calc(#{$sideNavWidth} + 58px);
  top: calc(60px + 58px);
  color: crimson;
  font-size: larger;
  white-space: pre-line;
}
