.age-chart-card {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 35px;
  padding: 30px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(154, 161, 171, 0.3) 0px 0px 10px;
  border-radius: 8px;
  min-height: 400px;
  overflow: hidden;
  min-width: 630px;
}
.age-chart-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
}

.age-chart-card-container {
  box-shadow: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.age-chart-card-container-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

.age-chart-card-container-item label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #000000;
  width: 100%;
}

.age-chart-card-container-progress {
  display: flex;
  background: #eefcff;
  border-radius: 25px;
  width: 50px;
  height: 197px;
  align-items: flex-end;
  margin-bottom: 8px;
}
.age-chart-card-container-progress span {
  margin-top: auto;
  margin-bottom: 10px;
  color: #ffffff;
  width: 100%;
  align-self: center;
  text-align: center;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.age-chart-card-container-progress div {
  background: linear-gradient(180deg, #00a9c8 0%, #5383db 42.19%, #7375e2 100%);
  border-radius: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  height: 0px;
  transition: height 1s;
}

.age-chart-card .histogram-zoomable-card-bottom-container {
  margin-top: 35px;
}

.age-chart-card .loading-area,
.age-chart-card .empty-container {
  height: 250px;
}
