@import "../../utils/Variables.scss";
.goodie-bag-allocation-for-option-c {
  .bag-num-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #4d5560;
    margin-top: 30px;
  }

  .bag-num {
    color: $navyBlue;
  }

  .bag-num-error {
    color: #e2002c;
  }
}
