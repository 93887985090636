.dhk {
  .header {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-self: flex-start;
    width: 100%;
    background-color: #fff;
    // border-bottom: 6px solid #e2002c;
    border-bottom: 1px solid #ccc;
    height: 91px;
    align-items: center;
    z-index: 20;

    top: 0px;
    position: fixed;
  }

  .header .back-button {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-right: auto;
  }

  .header .MuiButton-label {
    text-transform: none;
    font-size: 16px;
    font-family: 'Open Sans'; /*! OpenSansBold; */
    font-weight: 700;
  }

  .header-name {
    font-size: 16px !important;
    color: #000000 !important;
  }

  .header-bottom-line {
    height: 6px;
    background-color: #e2002c;
  }

  .header .menu-container {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .header .menu-list-item-font {
    font-family: 'Open Sans'; /*! OpenSansBold; */
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  }

  .header .menu-list-item {
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
  }

  div.menu-list-item {
    padding-left: 0px !important;
  }

  .header .menu-list-item:hover,
  .menu-list-item:active,
  .menu-list-item:focus {
    background: #fa6f8a;
    color: #ffffff;
  }

  .header-left {
    display: flex;
    margin-right: auto;
    width: -webkit-fill-available;
  }

  .header-logo {
    width: 136px;
    height: 55px;
    object-fit: contain;
    margin-right: auto;
  }

  .header-langauge {
    width: 19px;
    height: 19px;
    object-fit: contain;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .header-right {
    display: flex;

    height: 22px;
  }

  .header-right-line {
    border-left: 1px solid #dcdfe6;
    padding-left: 26px;

    height: 22px;
    margin-right: 0px !important;
  }

  .header-blod-font {
    font-family: 'Open Sans'; /*! OpenSansBold; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    /*  letter-spacing: 0.02em; */
    text-transform: uppercase;
  }

  div#header-right {
    display: flex;
  }

  // .header-right .language-container {
  //   margin-right: 30px;
  // }

  .header-right > div#header-right > div {
    color: #05218a;
    margin-right: 35px;
    display: flex;
    align-items: center;
  }

  .home-image {
    margin-top: 6px;
  }

  .header-right > div#header-right > div > label {
    color: #000000;
    margin-left: 5px;
  }

  .sperate-line {
    width: 1px;
    height: 25px;
    background-color: #bbbbbb;
  }

  .bottom-background {
    background: #393939 !important;
  }

  .language-list-container .MuiMenu-paper {
    margin-top: 48px;
  }

  .header .web-language-settings {
    flex-direction: row;
    position: relative;
    top: 0px;
    right: 0px;
    display: flex;
    z-index: 2;
    cursor: pointer;
  }

  .header .web-language-icon {
    color: #e2002c;
    font-size: 22px;
    height: 19px;
    align-self: center;
  }

  .header .web-language-label {
    // display: none;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    color: #000000;
    font-size: 15px;
    font-family: 'Open Sans'; /*! OpenSansBold; */
    font-weight: 700;
    padding: 0px;
    cursor: pointer;
  }

  .language-menu {
    width: 167px !important;
  }
  div#lang-menu > div {
    border-radius: 0px;
  }

  div#lang-menu > div > ul {
    padding: 0px !important;
  }

  .language-item {
    font-family: 'Open Sans';
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 10px 16px 10px 20px !important;

    width: auto !important;
    height: auto !important;

    &hover {
      background-color: transparent !important;
    }
  }

  .language-selected {
    color: #e2002c !important;
  }

  .header .web-keyboard-arrow-down {
    display: none;
  }

  .header-language {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .my-wallet-image {
    width: 19px;
  }

  .header-right .view-hover.view-hover-red {
    label.view-hover-red:hover {
      color: #e2002c !important;
      cursor: pointer;
    }
  }

  .header .view-hover-red:hover {
    color: #e2002c;
  }

  .header .header-name:hover {
    color: #e2002c;
    background-color: white;
  }

  .header-right > div#header-right .MuiButton-text .MuiButton-label:hover {
    color: #e2002c;
  }

  @media (max-width: 959px) {
    .header {
      height: 72px;
      /*padding-right: 30px; */
      padding-left: 20px;
      align-items: center;
    }
    .header-logo {
      width: 117px;
      height: 47px;
      object-fit: contain;
    }

    .header-right > div#header-right {
      display: none;
    }

    .header-right > div {
      margin-right: 0px;
    }

    .header-right .language-container {
      margin-right: 0px;
    }

    .header-language {
      margin-right: 0px;
    }
  }
}
