@font-face {
  font-family: 'Muli';
  src: url('./assets/fonts/Muli.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliBlack';
  src: url('./assets/fonts/Muli-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliBold';
  src: url('./assets/fonts/Muli-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliExtraBold';
  src: url('./assets/fonts/Muli-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/Muli.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishBlack';
  src: url('./assets/fonts/Muli-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishBold';
  src: url('./assets/fonts/Muli-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishExtraBold';
  src: url('./assets/fonts/Muli-ExtraBold.ttf') format('truetype');
}
// @font-face {
//   font-family: 'libel_suit';
//   src: url('./assets/fonts/libel-suit-rg-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'libel_suit';
//   src: url('./assets/fonts/libel-suit-sb-webfont.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }
@font-face {
  font-family: 'FuturaBold';
  src: url('./assets/fonts/Futura-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaMedia';
  src: url('./assets/fonts/FuturaMedium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaNormal';
  src: url('./assets/fonts/FuturaBTBook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('./assets/fonts/opensans-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/opensans-regular-webfont.woff') format('woff'),
    url('./assets/fonts/opensans-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans Extra';
  src: url('./assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Extra';
  src: url('./assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Semi';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Semi';
  src: url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

html,
body,
input,
textarea {
  font-family: 'Futura', 'libel_suit', 'Open Sans', 'Noto Sans TC',
    'Noto Sans SC', Arial, sans-serif, Verdana, Helvetica;
}
.offer-details-wrapper .offer-details-wrapper .content {
  font-family: 'Open Sans', 'Noto Sans TC', 'Noto Sans SC', Arial, sans-serif,
    Verdana, Helvetica;
}
.offer-details-wrapper .remarks {
  font-family: 'Open Sans', 'Noto Sans TC', 'Noto Sans SC', Arial, sans-serif,
    Verdana, Helvetica;
  clear: both;
}

@import './utils/Variables.scss';
@import '~bootstrap/scss/bootstrap';
@import './utils/BtnCommonTheme.scss';
@import './utils/CommonTheme.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundGrey !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
