.country-codes-prompt-container {
  width: 566px;
  max-height: 460px;
  padding: 35px 20px 15px;

  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);

  .base-prompt-close-button {
    top: 8px;
    right: 8px;
  }

  .base-prompt-other-body {
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;

    &::-webkit-scrollbar {
      width: 6px;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #C2C2C2;
      border-radius: 4px;
      opacity: 0.65;
    }
    
    .country-codes-prompt-other-body {
      // position: fixed;
      // top: calc(50%);
      // left: calc(50%);
      // transform: translate(-50%,-50%);
    
      // z-index: 10;
      // overflow-x: hidden;
      // overflow-y: auto;
    
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 20px 30px;
      flex-wrap: wrap;
      padding: 5px 0;

      label {
        width: 140px;
        flex-shrink: 0;
    
        color: #000;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 31.2px */
        white-space: pre-wrap;
      }
    }
  }

  .base-prompt-buttons {
    display: none;
  }
}
