.coupon-batch-export-tips {
  white-space: pre-line;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 12px;
  display: block;
  margin-top: 4px;
}

.coupon-batch-export-checkbox-container {
  display: flex;
  flex-direction: row;
  .checkbox-container:nth-child(n + 2) {
    margin-left: 40px;
  }
}
