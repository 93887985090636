@import '../../utils/Variables.scss';

.base-prompt-other-body {
  margin-top: 20px;
}

.scheduled-item-container {
  margin-bottom: 20px;
}

.schediled-item {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4e8dd7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0px;
  max-width: 700px;

  margin-left: 12px;
  display: flex;
  cursor: pointer;
}

.leave-general-alert {
  min-height: fit-content;
}

.leave-general-alert .base-prompt-header {
  max-width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scheduled-item-descroption {
  height: 24px;
  top: 24px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8c8c8c;
  margin-bottom: 0px;

  margin-left: 12px;
}

.display-dot {
  color: #404040;
  display: flex;
}

.hidden-dot {
  color: transparent;
}

.dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #404040;
  border-radius: 50%;
  margin-top: 12px;
}

.dot-blue {
  background-color: #4e8dd7;
}

.label-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
