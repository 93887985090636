.coupon-section-table-container {
  margin-top: 30px;
  font-family: Mulish;
  font-style: normal;
  color: #404040;

  .coupon-section-field-clumn {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    width: 120px;
    vertical-align: middle;
  }

  .coupon-section-field-input {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .coupon-section-field-input-pk {
    font-size: 16px;
    font-weight: 800;
  }

  .coupon-section-field-input-name {
    width: 170px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 20px;
    max-height: 40px;
  }
}
