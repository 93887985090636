@import '../../../utils/Variables.scss';
.alert-info-prompt {
  width: 673px;
  max-height: 373px;
  margin-right: 87px;
  align-self: flex-start;
  margin-top: 6px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
  overflow-y: scroll;
}

.alert-info-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: inherit;
  width: 735px;
}