
.table-prompt {
    font-family: Mulish;
    .filter-area {
        position: absolute;
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }

    .table-area {
        display: flex;
        flex-direction: column;

        label.table-title {
            font-weight: 800;
            font-size: 16px;
            line-height: 20.08px;
            color: #4D5560;
            margin-bottom: 12px;
        }

        .base-simple-table-header {
            margin-top: 12px;

            border-collapse: separate;
            border-spacing: 0;
            thead {
                position: sticky;
                top: 0;
                background: #fff;
                width: fit-content;

                tr:first-child > td:first-child {
                    border-top: 1px solid #ccc;
                }

                tr > td:first-child {
                    width: 5%;
                    border-left: 1px solid #ccc;
                }

                // td: {
                //     border: 1px solid #000;
                // }
            }

            td {
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
            }
        }

        .base-simple-table-div {
            height: 800px;
            width: 1310px;
            overflow: auto;

            border: 1px solid #DADADA;
            border-radius: 8px;

            .base-simple-table {
                margin-top: 0px;

                border-collapse: separate !important;
                border-spacing: 0;

                // border-radius: 8px;
                // -moz-border-radius: 8px;
                // -webkit-border-radius: 8px;

                td {
                    border-bottom: 1px solid #DADADA;
                    border-right: 1px solid #DADADA;

                    padding: 12px 10px 12px 10px;
                    text-align: center;
                }
                td:nth-child(2) {
                    width: 68px;
                    .all-list-scrollable-area-table-item-common {
                        min-width: unset;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px;
                    }
                }

                thead {
                    position: sticky;
                    top: 0;
                    background: #fff;
                    width: fit-content;

                    tr:first-child > td:first-child {
                        // border-top: 1px solid #DADADA;
                        // -moz-border-radius: 8px 0 0 0;
                        // -webkit-border-radius: 8px 0 0 0;
                        // border-radius: 8px 0 0 0;

                        div {
                            display: flex;
                            margin-left: auto;
                            div.all-list-filter-container {
                                position: inherit;
                                align-self: center;
                            }

                            .list-filter-list-search-section {
                                width: 288px;
                                margin-left: 8px;
                                div {
                                    min-width: 288px;
                                }
                            }

                        }
                    }

                    // tr:first-child > td:last-child {
                    //     -moz-border-radius: 0 8px 0 0;
                    //     -webkit-border-radius: 0 8px 0 0;
                    //     border-radius: 0 8px 0 0;
                    // }

                    tr > td:first-child {
                        width: 5%;
                        // border-left: 1px solid #DADADA;
                    }

                    td:last-child {
                        border-right: none;
                    }
                }
            }

            .loading-area {
                height: 600px;
            }
        }

        .base-simple-table-div .base-simple-table tbody {
            // tr:last-child {
            //     td:first-child {
            //         -moz-border-radius: 0 0 0 8px;
            //         -webkit-border-radius: 0 0 0 8px;
            //         border-radius: 0 0 0 8px;
            //     }
            //     td:last-child {
            //         -moz-border-radius: 0 0 8px 0;
            //         -webkit-border-radius: 0 0 8px 0;
            //         border-radius: 0 0 8px 0;
            //     }
            // }

            tr > td:first-child  {
                width: 5%;
                // border-left: 1px solid #ccc;
            }

            td:last-child {
                border-right: none;
            }

            tr:last-child > td {
                border-bottom: none;
            }

        }

    }
}

.location-table-prompt .base-simple-table {
    td:nth-child(4) {
        width: 175px;
        max-width: 200px;
    }
    td:nth-child(5) {
        width: 250px;
        max-width: 300px;
    }
    td:last-child {
        width: 110px;
    }
}

@media screen and (max-width: 1400px) {
    .table-prompt .table-area .base-simple-table-div {
        width: 1000px;
        height: 800px;

        .base-simple-table tr:first-child > td:first-child div .list-filter-list-search-section {
            width: 214px !important;
            margin-left: 10px;
            div {
                min-width: unset !important;
            }
        }

    }
    .goodie-bag-table-prompt, .location-table-prompt {
        .table-area .base-simple-table-div .base-simple-table tr:first-child > td:first-child div .list-filter-list-search-section {
            width: 237px !important;
        }
    }
}