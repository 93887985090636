.download-prompt {
  width: 665px;
}

.download-area-part > .row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  label {
    margin-right: auto;
    width: 72%;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #404040;
  }
}

.row > button {
  margin-left: auto;
  border: 1px solid #4e8dd7;
}

.row > a {
  margin-left: auto;
  border: 1px solid #4e8dd7;

  &:hover {
    color: #fff !important;
  }
}

.sperate-line {
  border: 1px dashed #dadada;
  margin: 9px 0px 29px 0px;
}

.download-area-reference-part > .row {
  margin-left: 16px;
  margin-bottom: 17px;
  label {
    width: 70%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-transform: capitalize;

    color: #404040;
  }

  button {
    background-color: #fff;
    border: 0px solid #fff;
    color: #4e8dd7;
    outline: none;

    &:focus,
    &:active {
      border: 0px !important;
      background-color: #fff !important;
    }
  }
}
