@import '../../../../utils/Variables.scss';

.entitlement-content-section .content-no-preview {
  max-width: 100%;
}

.merchant-table {
  margin-top: 30px;
}

.merchant-table tr th {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.merchant-table tr td {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.lebel-right-button,
.lebel-right-button:hover,
.lebel-right-button:focus,
.lebel-right-button:active {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  color: $linkBtnColor;
  font-size: 16px;
  font-weight: 800;

  padding-top: 0px;
}
