.sortable-div-container {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.error {
    border: 1px dashed #ff0000;
  }
}

.sortable-container {
  display: flex;
  flex-direction: column;

  .create-section-label-bottom-space, .tips-message {
    margin-bottom: 12px;
  }
  .clear-all {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 800;
    line-height: 17.57px;
    text-align: center;
    color: #4E8DD7;

    margin-left: 12px;
  }
}

.base-sortable-row {
  max-width: 800px;
  padding: 16px;
  gap: 0px;
  border-radius: 10px;
  border: 1px solid #DADADA;
  display: flex;
  align-items: start;
  justify-content: space-between;

  .sortable-left {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .row-index {
      min-width: 27px;

      font-family: Mulish;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      text-align: left;
      color: #404040;
    }

    .sortable-content {
      .row-title {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #404040;

        display: flex;
        gap: 5px;
        .drop-icon {
          width: 12px;
        }
      }
      .row-content {
        ul {
          margin: 0;
          li {
            font-family: Mulish;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #979797;
            list-style-type: disc;
          }
          li > * {
            vertical-align: top;
          }
        }
        img {
          width: 150px;
        }
      }
    }
  }
  .sortable-right {
    min-width: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 5px 16px;

    img {
      width: 27px;
      cursor: pointer;
    }
  }
}
