@import '../../../utils/Variables.scss';

.bag-num-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  /* Aillia_1.0/form_title */

  color: #4d5560;

  .bag-num {
    color: $navyBlue;
  }

  .bag-num-error {
    color: #e2002c;
  }
}

.refill-title-area {
  margin-top: 24px;
  margin-bottom: 14px;
}

.refill-bag-num-title {
  font-size: 18px;
  line-height: 23px;

  margin-top: 8px;
  margin-bottom: 0px;
}

.blod-title {
  font-weight: bold;
}

.slot-head {
  display: flex;
}

.flex-right {
  margin-left: auto;
}

.slot-label {
  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  margin-top: 8px;
  margin-bottom: 8px;

  /* Aillia_1.0/form_title */

  color: #4d5560;
}

.slot-title {
  font-weight: 800;
  margin-right: 8px;
}

.button-left-area {
  // margin-right: auto;
  display: flex;
  width: 100%;

  .left-create {
    margin-left: 0px;
    margin-right: 0px;
  }

  .left {
    margin-left: 0px;
    margin-right: auto;
  }

  .right {
    margin-left: 22px;
    margin-right: auto;
  }

  .right-assign {
    margin-left: auto;
    margin-right: 33px;
  }
}

.slot-delete-icon {
  width: 23px;
  height: 24px;
  margin-top: 5px;

  cursor: pointer;
}
