.change-password {
  padding: 0px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #4e8dd7;
  align-self: center;
  box-shadow: none;
  margin-left: 10px;
  background: none;
  border: none;
}
.change-password:focus,
.change-password:hover,
.change-password:not(:disabled):not(.disabled):active {
  text-decoration: none;
  color: #4e8dd7;
  border: none;
  outline: none;
}
