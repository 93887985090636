@import '../../utils/Variables.scss';

.base-save-area {
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-left: 0;
  transition: padding-left 0.5s;
  height: $stepContinueAndBackNavHeight;
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  z-index: 1;
}

.base-save-area-menu-show {
  padding-left: $sideNavWidth;
}

.base-save-and-back-component-button {
  align-self: center;
  text-align: center;
}

.base-back-button,
.base-back-button:focus {
  margin-left: 20px;
  box-sizing: border-box;
  margin-right: auto;
}

.base-save-button {
  margin-right: 20px;
  margin-left: auto;
}

.base-save-button-temp-button,
.base-save-button-temp-button:focus {
  margin-right: 20px;
  margin-left: auto;
}

.base-continue-button {
  margin-right: 20px;
  margin-left: auto;
}

.base-delete-button,
.base-delete-button:focus {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4e8dd7;
  border: 0px;
  background-color: #ffffff;
  margin-left: 44px;
  outline: 0px;
}

.base-temp-save-area {
  align-self: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .base-save-area-menu-show {
    padding-left: 0px;
  }
}