@import '../../utils/Variables.scss';

.overview > .all-list-scrollable-area-pagination {
  border-top: 1px solid #c2c2c2;
}

.overview-all-list-table {
  border-collapse: collapse;
  width: 100%;
}

.overview-all-list-table tr th {
  position: sticky;
  top: 0px;
  background: white;
  box-shadow: inset 0 -1px 0 #c2c2c2;
  border-bottom: 1px solid #999999;
  padding: 0px 0px;
}

.overview-all-list-table td {
  padding: 0px 0px;
  border: none;
}
.overview-all-list-table td:nth-child(1),
.overview-all-list-table th:nth-child(1) {
  width: 52px;
}
.overview-all-list-table td:nth-child(3),
.overview-all-list-table th:nth-child(3) {
  border-right: none;
}
.overview-all-list-table tbody tr:nth-child(even) {
  background-color: white;
}

.overview-all-list-table tbody tr:nth-child(odd) {
  background: white;
}

.overview-all-list-table tbody tr {
  padding: 0px 26px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #626262;
}

.overview-all-list-table thead tr th div {
  height: auto;
  padding: 24px 20px;
  text-align: center;
}

.customer-table-overview
  > div.overview-list-section.common-table-overflow
  > table
  > thead
  > tr
  > th
  > div
  label {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.customer-overview-header-th div {
  display: flex;
  justify-content: flex-end;
}
.customer-table-overview
  > div.overview-list-section.common-table-overflow
  > table
  > tbody
  > tr
  > td {
  height: auto;
  padding: 13px 25px;
}

.customer-table-overview
  > .common-table-overflow
  > table
  > thead
  > tr
  > th
  > div
  > img {
  height: auto;
}

.overview-list-section {
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px 0px rgba(154, 161, 171, 0.3);
  background: white;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.customer-overview-row {
  padding-bottom: 0px !important;
}

.customer-overview-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-right: 38px;
}

.customer-overview-title-container .overview-campaigns-title {
  margin-top: 0px;
  margin-left: 0px;
}

.customer-overview-title-container :nth-child(2) {
  margin-left: auto;
}

.customer-table-overview {
  margin-top: 40px;
}

.customer-table-overview label {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customer-table-overview .common-table-overflow {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  margin: 0px 40px 40px 40px;
  width: auto;
  min-height: 0px;
  padding: 22px 33px;
  margin-top: 34px;
}

.customer-table-overview
  > div.overview-list-section.common-table-overflow
  > form {
  align-self: flex-end;
}

.customer-over-page-info {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-top: 3px;
  width: 100%;
  text-align: end;
  padding: 0px 35px;
}

.customer-overview-row-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
  margin-left: 38px;
}
