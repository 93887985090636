.total-number {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #4d5560;

  margin: 8px 0;
}

.goodiebag-record-total-number {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #4d5560;
  margin: 8px 0 -2px;
}
