.create-coupon-step-three-container {
  margin: 30px;
  padding: 30px;
  background-color: #ffffff;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  &.has-bottom-margin {
    margin-bottom: 100px;
  }
}

.create-coupon-step-three-campaign-alert {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin-bottom: 18px;
}
