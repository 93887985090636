@import '../../../utils/Variables.scss';

.bag-num-title-normal {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  margin-top: 24px;
  /* Aillia_1.0/form_title */

  color: #404040;

  .bag-num {
    margin-left: 5px;
    font-weight: bold;
    color: $navyBlue;
  }

  .bag-num-error {
    color: #e2002c;
  }
}

.fortune-bags-allocation-other-body{
  margin-top: 0px !important ;
}

.fortune-bags-allocation-button{
  margin-right: 0px !important;
  margin-left: auto !important;
}

.fortune-bags-caution-area{
  margin-top: 0px !important;
}

.fortune-bags-close-button{
  margin-top: 20px !important;
}