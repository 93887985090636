@import '../../../utils/Variables.scss';

.goodie-bag-entitlement-button {
    margin-right: 30px;
}

.goodie-bag-detail-table {
  border-color: #dadada;

  td {
    padding: 28px 21px 27px 21px;

    font-family: Mulish;
    font-style: normal;

    vertical-align: baseline;
  }

  td:nth-child(1) {
    width: 30%;
  }

  td:nth-child(2) {
    width: 20%;
  }

  td:nth-child(3) {
    width: 50%;
  }

  thead > tr > td {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;

    color: #404040;
  }

  tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    color: #000000;
  }

  label.batch-dselimiter {
    color: #909090;

    margin-left: 10px;
    margin-right: 10px;
  }

  label.batch-updatetime {
    color: #909090;
  }

  label.batch-no {
    color: #404040;
  }
}

.goodie-bag-preview-area {
  margin-right: 0;
  margin-left: auto;
  float: right;
}