.wording-new-line {
  white-space: pre-line;
}
.see-reference-button {
  display: block;
  margin-top: 8px;
  padding: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4e8dd7;
}

.bottom-button-container {
  height: 74px;
  width: 100%;
  .preview-button {
    position: relative;
    float: right;
    top: 28px;
    margin-right: 10px;
  }
  .save-button {
    position: relative;
    top: 28px;
    float: right;
  }
}
