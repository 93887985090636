@import '../../../utils/Variables.scss';

.create-campaign-brand-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.general-section-add-new-brand {
  padding-bottom: 3px;
  margin-top: 3px;
  // align-self: center;
}

.general-section-add-new {
  padding-bottom: 3px;
  margin-top: 12px;
  // align-self: center;
}

.align-left {
  margin-left: 0;
  margin-right: auto;
}

.general-section-limit-wording-label {
  margin-top: 30px;
  margin-bottom: 4px;
}

.general-section-limit-wording-input {
  margin-top: 16px;
}

.general-section-limit-wording-tips {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  color: #979797;
}
