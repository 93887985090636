.dashboard-top {
  padding: 30px;
  height: 1038px;

  .dashboard-card-top-10-redemption-title {
    margin-top: 0;
  }
  .header {
    margin: 13px auto;
    width: 100%;
    display: flex;

    font-size: 10.525px;
    line-height: 13px;
    /* identical to box height */

    color: #979797;
  }

  .dashboard-card-title {
    margin-top: 0px;
  }

  .top-font {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    font-size: 16px;
  }

  .header-0 {
    margin-right: auto;
    margin-left: 0;
  }

  .header-1 {
    margin-right: 0;
    margin-left: auto;
  }

  .top-title {
    font-size: 14px;
    color: #000000;

    transform: rotate(0.1deg);
  }
}

.dashboard-coupon-card {
  .dashboard-segments-buttons {
    height: 40px;
    margin: 20px auto;
    width: 100%;
    display: flex;
  }

  .filter-button-group {
    width: 100%;
    .btn-primary {
      color: #979797;
      background-color: #fff;
      border-color: #979797;
    }

    .filter-button-group-item {
      width: 50%;
      height: 40px;
    }

    .filter-button-group-item,
    .filter-button-group-item:left {
      background-color: #fff;
      border-color: #979797;
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      /* identical to box height, or 171% */

      text-align: center;

      height: 40px;
      color: #979797;
    }

    // .filter-button-group-item:active,
    // .filter-button-group-item:focus
    .filter-button-selectd,
    .filter-button-group-item:hover {
      background: #ebf2fc;
      border: 1.5px solid #4e8dd7;
      box-sizing: border-box;
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 131% */

      height: 40px;
      color: #4e8dd7;
    }

    .filter-button-selectd-0 {
      border-radius: 20px 0 0 20px;
    }

    .filter-button-selectd-3 {
      border-radius: 0 20px 20px 0;
    }
  }
}

.dashboard-coupon-card.dashboard-top .top3,
.dashboard-coupon-card.dashboard-top .others {
  .progress .progress-bar {
    min-width: 50px;
  }
}

.dashboard-coupon-card.dashboard-top .dashboard-card-data-total {
  font-family: Mulish;
  font-style: normal;
  color: #979797;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  .dashboard-card-data-total-line {
    display: block;
  }
  .dashboard-card-data-number {
    font-weight: 800;
    margin-left: 4px;
  }
}
