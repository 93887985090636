.gamification-campaign-generate-unique-link-prompt {
  width: 800px;
  padding: 30px;
  
  .base-prompt-other-body {
    margin-top: 0px;
  }
  .gamification-campaign-generate-unique-link-content {
    display: flex;
    flex-direction: column;

    .create-section-label-bottom-space {
      margin: 30px 0 13px;
    }
    input {
      width: 200px;
      margin-bottom: 0px;
    }
    .custom-textarea-remakrs {
      width: 600px;
      height: 200px;
      padding: 8px 12px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      border-radius: 4px;
  
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
  
      resize: none;
  
      &:focus, &:focus-visible {
        border-color: #4e8dd7;
        outline-color: #4e8dd7;
      }
    }
  }
  .base-prompt-buttons {
    padding-top: 10px;
  }
}
