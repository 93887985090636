.campaign-category-image-title {
  margin-bottom: 6px;
}

.create-campaign-category-image-reminder {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
}

.create-campaign-category-image-reminder-2 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-top: 6px;
}

.see-reference-button {
  border: none;
  background: transparent;
  border-color: transparent;
  outline: none !important;
}

.reference-promt-iamge {
  margin-top: 21px;
  margin-bottom: -50px;
  display: flex;
  flex-direction: row;
}

.reference-promt-before-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;

  width: 212px;
  height: 268px;

  background: #f2f3f6;
  border-radius: 8px;
}

.reference-promt-after-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;

  width: 212px;
  height: 268px;

  background: #f2f3f6;
  border-radius: 8px;
}

.reference-promt-arrow {
  margin: 134px -7px 134px 33px;
  box-sizing: border-box;
  width: 88px;
  height: 0px;
  border: 1px solid #4d5560;
}

.arrow-right{
  margin-right: 33px;
  border: solid #4d5560;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
