.coupon-barcode-container,
.coupon-barcode-container:hover {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: inherit !important;
  border-radius: 3.124px;
  border: 0.39px solid #c2c2c2;
  background: #fff;
  width: 184px;
  margin-top: 12px;
  padding: 1px;
  overflow: hidden;
  > div {
    width: 100%;
  }
  .hover-barcode-container {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: fit-content;
    max-width: calc(100% - 472px);
    overflow: scroll;
    .hover-barcode-title {
      color: #4d5560;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 15.734px;
      margin-bottom: 16px;
    }
    .hover-barcode-inner-container {
      margin: 0 auto;
      border-radius: 5.994px;
      border: 0.749px solid #c2c2c2;
      background: #fff;
      padding: 1px;
    }
  }
}
