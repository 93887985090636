.mission-label-container {
  position: relative;
  .delete-icon {
    position: absolute;
    left: 632px;
    top: 60px;
    img {
      width: 33px;
      height: 33px;
    }
  }
}
.mission-label-container {
  display: flex;
  align-items: center;
  // gap: 10px;
  .create-section-label-bottom-space {
    margin: 0;
  }
  input {
    width: 200px;
    margin-left: 10px;
  }
  .delete-icon {
    position: unset;
    img {
      // width: 24px;
      // height: 24px;
      width: 40px;
      height: 40px;
    }
  }
  .delete-icon.hidden {
    visibility: hidden;
  }
}
.mission-label-container:nth-child(n+1) {
  margin-top: 12px;
}

.mission-label-add-btn {
  width: fit-content;
}