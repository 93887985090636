.goodie-bag-step-four-properties {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.without-margin-top {
    margin-top: 0px !important;
}