.histogram-zoomable-card {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 35px;
  padding: 30px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(154, 161, 171, 0.3) 0px 0px 10px;
  border-radius: 8px;
  min-height: 696px;
  overflow: hidden;
}

.histogram-zoomable-card-chart-header {
  display: flex;
  flex-direction: column;
}

.histogram-zoomable-card-chart-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
}

.histogram-zoomable-card-chart-hint {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #979797;
  margin-bottom: 50px;
}

.histogram-zoomable-card .dashboard-overview-link {
  margin-left: auto;
  margin-top: 18px;
  margin-bottom: 0px;
}

.histogram-zoomable-card-title-container {
  display: flex;
  flex-direction: row;
}

.histogram-zoomable-card-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
}

.histogram-zoomable-card-title-tab {
  margin-left: auto;
  height: 40px;
  border: 1px solid #e6f0fd;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
}

.histogram-zoomable-card-title-tab label {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 0px 20px;
  background: white;
  color: #979797;
  border-right: 1px solid #e6f0fd;
  height: 40px;
  cursor: pointer;
  min-width: 163px;
  text-align: center;
}

.histogram-zoomable-card-title-tab .histogram-zoomable-card-title-tab-selected {
  color: #4e8dd7;
  background: #e6f0fd;
}

.histogram-zoomable-card-data-title-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.histogram-zoomable-card-percentage {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #979797;
}

.histogram-zoomable-card-count {
  font-weight: 800;
}

.histogram-zoomable-card-item-container {
  height: 500px;
  box-shadow: none;
  border: none;
}

.histogram-zoomable-card-item-container :first-child {
  box-shadow: none;
}

.histogram-zoomable-card-bottom-container {
  margin-top: 48px;
}

.histogram-zoomable-card-bottom-container .dashboard-overview-link {
  margin-top: 0px;
}

#center-axis-container > div:nth-child(2) {
  overflow-x: clip;
}

.histogram-zoomable-card-item-container .loading-area {
  height: 500px;
}

.histogram-zoomable-card-item-container .Component-root-3 {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.histogram-zoomable-card-chart-header > div > div.all-list-filter-container {
  position: relative;
  right: 0px;
  top: 0px;
  margin-left: auto;
}

.histogram-chart-tips.caution-container .arrow::after {
  border-top-color: rgba(0, 33, 72, 0.9);
}

.histogram-chart-tips > div.caution-content.popover-body {
  text-align: center;
  justify-content: center;
}

.histogram-chart-tips.caution-container .caution-content.popover-body {
  word-break: break-all;
}

.histogram-chart-attribute-reset {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: #979797;
  cursor: pointer;
}

.histogram-chart-attribute-reset.popup-reset {
  position: absolute;
  top: 128px;
  right: 40px;
}

.histogram-zoomable-card {
  .starred-campaign-filter {
    padding: 0;
    margin-left: auto;

    div.starred-check-area {
      margin-right: 41px;
    }

    .dashboard-campaign-selector {
      max-width: 70%;
    }

    .dashboard-campaign-selector > button {
      img {
        // margin-left: auto;
        position: absolute;
        right: 80px;
      }
    }
  }
}
