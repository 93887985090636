.search-to-show-page {
  color: #404040;
  text-align: center;
  font-size: 16px;
  font-family: Mulish;
  font-weight: 800;
  line-height: 21px;
  width: 100%;
  margin-top: 59.5px;
  @media (min-width: 769px) {
    position: absolute;
    top: calc(55% - 59.5px);
  }
}

.search-request-limit-exceeded-page {
  color: #f00;
  text-align: center;
  font-size: 16px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  line-height: 21px;
  width: 100%;
  margin-top: 59.5px;
  white-space: pre-line;
  @media (min-width: 769px) {
    position: absolute;
    top: calc(55% - 59.5px);
  }

  .content {
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 8px 15px 0;
  }
}

.search-no-result-page {
  color: #f00;
  text-align: center;
  font-size: 16px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  line-height: 21px;
  width: 100%;
  margin-top: 59.5px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: pre-line;
  @media (min-width: 769px) {
    position: absolute;
    top: calc(55% - 59.5px);
  }
}

.check-otp-count-container {
  height: 20px;
  background-color: #f8f9fc;
  margin: 8px 0;
  .check-otp-count-text {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    height: 20px;
    position: relative;
    float: right;
    margin-right: 15px;
  }
}

.check-otp-loading-area {
  background-color: inherit;
  height: -webkit-fill-available;
  position: absolute;
  @media (max-width: 768px) {
    height: auto;
    position: inherit;
  }
}
