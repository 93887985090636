.upload-description {
  color: #8c8c8c;
  line-height: 21px;

  white-space: pre-line;
  max-width: inherit;
}

.upload-file-area {
  display: flex;
  flex-direction: column;

  justify-content: center;
  text-align: center;
  /* column-gap: 4px; */
  //   gap: 40px;

  background: #ffffff;
  border: 2px dashed #dadada;
  box-sizing: border-box;
  border-radius: 10px;

  padding: 40px;
}

.upload-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #404040;
}

.upload-file-name {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #404040;

  margin-bottom: 20px;
}

.upload-select-button,
.upload-select-button:focus,
.upload-select-button:hover,
.upload-select-button:not(:disabled):not(.disabled):active,
.upload-select-button:not(:disabled):not(.disabled):active:focus,
.upload-select-button:disabled {
  width: fit-content;
  height: auto !important;
  margin: auto;
  padding: 8px 16px !important;

  font-size: 14px !important;
  line-height: 18px !important;
}
