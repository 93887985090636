.terms-and-conditions-detail .first-section-title.overview-section-title {
    margin: 0;
}

.terms-and-conditions-detail .second-section-content.related-items-content .detail-page-applied {
    line-height: 24px;
}

.terms-and-conditions-detail .second-section-content.related-items-content div:first-child{
    margin-top: 8px;
}

.terms-and-conditions-detail .second-section-content.related-items-content div:last-child{
    margin-bottom: 8px;
}
.terms-and-conditions-related-items-container {
    max-height: 500px;
    overflow: auto;
}