@import '../../../utils/Variables.scss';

.create-step-four-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.preview-prop-area {
  background: #ffffff;
  /* Aillia_1.0/black6 */

  border: 1px solid #dadada;
  box-sizing: border-box;
  /* shadow */

  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
  border-radius: 8px;

  margin-bottom: 6px !important;
  padding: 10px 20px;
}

.preview-prop-font {
  font-family: Mulish;
  font-style: normal;

  font-size: 14px;
  line-height: 40px;
  /* or 286% */

  color: #404040;
}

.preview-prop-title {
  font-weight: 800;
  text-transform: uppercase;
}

.preview-prop-item {
  font-weight: normal;
  cursor: pointer;
}
