.coupon-overview-filter {
    margin-top: 40px;
    margin-left: 40px;
    position: relative;
}

.coupon-overview-filter-head {
    max-width: 515px;
    display: flex;
}

.coupon-overview-filter-title {
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #002148;
}

.coupon-overview-filter-reset-all {
    margin-left: auto;
    margin-right: 15px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    text-align: right;
    color: #979797;
    cursor: pointer;
}
