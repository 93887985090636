.extend-goodie-bag-date-prompt {
  max-width: 600px;
  padding: 32px;
  
  .base-prompt-close-button {
    top: 32px;
    right: 32px;
  }
  
  .base-prompt-header {
    margin-right: 30px
  }

  .base-prompt-other-body {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .create-section-label {
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #4D5560;
    }

    .landing-title-margin-bottom {
      margin-top: 32px;
      margin-bottom: 12px;
    }

    .section-value {
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      color: #404040;
    }

    .extend-to-date-picker {
      padding: 8px 12px;
      height: 40px;

      input {
        color: #404040;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }

    .extend-to-date-picker {
      width: 280px;
      border-radius: 4px;
      border: 1px solid #C2C2C2;
      background: #FFF;
      color: #404040;
    }

    .custom-textarea-remakrs {
      height: 200px;
      padding: 8px 11px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      border-radius: 4px;
  
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
  
      resize: none;
  
      &:focus, &:focus-visible {
        border-color: #4e8dd7;
        outline-color: #4e8dd7;
      }
    }
    @media (max-width: 900px) {
      .custom-textarea-remakrs {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      .custom-textarea-remakrs {
        width: 100%;
        height: 160px
      }
    }

  }
}
