.rate-limit-item {
  margin-top: 4px;
  margin-bottom: 8px;
  
  .rate-limit-admin-group-select-outside {
    width: 362px !important;
    
    .rate-limit-admin-group-select {
      margin: 0;
    }
  }
  input {
    margin-bottom: 0;
  }
  label {
    margin-bottom: 0;
  }
  .delete-rate-limit-button {
    width: 28px;
    height: 28px;
  }
}
.rate-limit-item-errors {
  position: relative;
  height: 26px;

  .error-message-administratorGroup {
    margin: 0 0 8px 0;
    position: absolute;
  }
  .error-message-threshold {
    margin: 0 0 8px 0;
    position: absolute;
    left: 392px;
  }
  .error-message-duration {
    margin: 0 0 8px 0;
    position: absolute;
    left: 584px;
  }
}

.add-rate-limit-button {
  margin-top: 4px;
}
