@import '../../utils/Variables.scss';
.dashboard-campaign-card {
  float: left;
  height: 568px;
  min-width: 465px;
  width: 49%;
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  position: relative;
  font-family: Mulish;
  font-style: normal;

  padding: 0 30px;
  // .dashboard-card-title{
  //   margin-left: 30px;
  // }

  .dashboard-campaign-card-main {
    margin: 20px 30px 0 30px;
  }
  .dashboard-campaigns-type {
    display: block;

    .dashboard-campaigns-type-title {
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #002148;
    }
    .dashboard-campaigns-type-data {
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;

      .dashboard-campaigns-type-data-separate {
        width: 0;
        min-height: 143px;
        border: 1px solid #dadada;
      }
      .dashboard-campaigns-type-data-single {
        display: inline-block;
        flex-grow: 1;
        margin-left: 15px;
        min-height: 143px;
      }
      .dashboard-campaigns-type-data-single-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #979797;
        display: block;
      }
      .dashboard-campaigns-type-data-single-value {
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
        color: #404040;
        display: block;
        margin-top: 40px;
      }
      .dashboard-campaigns-type-data-single-change {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #4e8dd7;
        margin-top: 2px;
      }
    }
  }

  .campaign-type-card {
    border: 1px solid #dadada;
    box-sizing: border-box;
    border-radius: 8px;

    border-left: 10px solid #9fa0f8;

    margin-bottom: 20px;

    div.dashboard-coupon-item-container {
      margin-left: 4px;
    }
  }
}
