.interest-preference-detail-section {
  margin-top: -60px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}
.interest-preference-detail-section .coupon-overview-container {
  margin: 0 0 100px;
}
.interest-preference-detail-section button {
  margin-top: 20px;
}
