@import '../../utils/Variables.scss';
.dashbard-coupon-card {
  height: 568px;
  min-width: 465px;
  margin-top: 32px;
  margin-right: 2%;
  float: left;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  width: 49%;
  position: relative;
  padding: 30px;
}
.dashboard-coupon-indicator {
  width: 32px;
  height: 430px;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 46px;
}

// .dashbard-coupon-card .histogram-zoomable-card-chart-title {
//   // margin-bottom: 24px;
// }

.dashboard-coupon-seprator {
  background: #dadada;
  height: 1px;
  width: 100%;
  margin: 11px 0px;
}

.dashboard-coupon-item-container {
  padding: 15px 20px;
  margin-left: 60px;
  height: 114px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-coupon-item-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #979797;
}

.dashboard-coupon-item-text-container {
  display: flex;
  flex-direction: column;
}

.dashboard-coupon-count-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #404040;
  // margin-top: 16px;
}

.dashboard-coupon-percentage-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #4e8dd7;
  margin-left: 4px;
}

.coupons-overview-link {
  right: 38px;
  position: absolute;
  bottom: 30px;
}

.dashboard-coupon-redeem-container {
  display: flex;
  flex-direction: row;
  margin-left: 60px;
}

.dashboard-coupon-redeem-container .dashboard-coupon-item-container {
  margin-left: 0px;
  padding-right: 0px;
  width: 50%;
}

.dashboard-coupon-redieem-seprator {
  height: 124px;
  width: 1px;
  margin: 0px 9px;
  background: #dadada;
}

.dashboard-coupon-item-container :first-child {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
}

.dashboard-coupon-item-label-caution {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #979797;
}
