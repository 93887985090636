.custom-tag-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  flex: none;
  order: 0;
  align-self: center;
  border-radius: 4px;
  padding: 4px 8px;
  // margin-bottom: 0px;
  // height: 26px;
  height: auto;
}

.custom-tag-label-publish {
  color: #178768;
  background: #dff1ec;
}

.custom-tag-label-publish-full-height {
  color: #178768;
  background: #dff1ec;
  height: auto;
}

.custom-tag-label-un-publish {
  color: #757575;
  background: #eeeeee;
}

.custom-tag-label-expired {
  color: #d13636;
  background: #fbe1e1;
}

.custom-tag-label-downloading {
  color: #4e8dd7;
  background: rgba(24, 150, 249, 0.25);
}

.custom-tag-label-waiting {
  color: #d89616;
  background-color: #ffe9b0;
}

.custom-tag-label-scheduled {
  color: #092145;
  background-color: rgba(9, 33, 69, 0.1);
}
