@import '../../utils/Variables.scss';

.multiple-select-option-container {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  max-width: 330px;
  width: 100%;
  height: 239px;
  // overflow-x: auto;
}

.multiple-select-option-add {
  // margin-left: 16px;
  padding-bottom: 3px;
  margin-top: 3px;
}

.disabled-multiple-select-option-item,
.multiple-select-option-item {
  height: 40px;
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #404040;
  padding: 0px 0px;
  // overflow-x: hidden;
  // text-overflow: ellipsis;
  padding-left: 12px;
  margin-bottom: 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a:active {
    color: #2b84ee;
    background-color: #ffffff;
  }
}

.multiple-select-option-item-inner-text {
  line-height: 21px;
  padding: 10px 0px;
}

.multiple-select-option-item:hover {
  cursor: pointer;
}

.multiple-select-option-item:active,
.multiple-select-option-item:focus,
.multiple-select-option-item-selected {
  color: #2b84ee;
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.multiple-select-option {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #404042;
  padding: 0px;
  padding-left: 12px;
  margin-bottom: 0px;
}

.multiple-select-reminder-underline {
  border-bottom: 1px #c2c2c2 solid;
  margin: 0px 10px;
}

.multiple-select-all:hover {
  cursor: pointer;
}

.multiple-select-option-selected {
  background-color: #2b84ee;
  color: white;
}

.multiple-select-pointer-area-with-2filters {
  max-height: 102px;
  min-height: 40px;
  overflow-x: auto;
  height: 100%;
}

.multiple-select-pointer-area-with-filter {
  max-height: 136px;
  min-height: 40px;
  overflow-x: auto;
  height: 100%;
}

.multiple-select-pointer-area-without-filters {
  max-height: 193px;
  min-height: 40px;
  overflow-x: auto;
  height: 100%;
}

.multiple-select-pointer-area:hover {
  cursor: pointer;
}

.multiple-select-all {
  font-weight: bold;
}

.multiple-select-group {
  padding-right: 12px;
}

.option-and-add-button {
  display: flex;
  flex-direction: row;
}

.option-add-image {
  height: 40px;
  width: 40px;
  margin-left: 12px;
  object-fit: contain;
}

.option-add-image:hover {
  cursor: pointer;
}

.custom-multiple-select-error {
  border: 1px solid red;
}

.multiple-select-filter-bar {
  position: relative;
  margin: 17px 9px 0;

  .filterable-search-bar-icon {
    left: 10px;
  }
}

.multiple-select-filter-tiems-bar {
  justify-content: space-around;
  margin-top: 8px;

  button.subfilter-item-btn {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    font-family: Muli;
    font-weight: 800;

    &.filter-btn-selected,
    &:focus {
      background-color: #2b84ee;
      color: #ffffff;
      border: 1px solid #2b84ee;
      outline: 0px;
    }
  }
}

.selector-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector-loading-imgae {
  width: 42px;
  height: 42px;
  object-fit: contain;
}
