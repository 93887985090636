@import '../../../../utils/Variables.scss';

.entitlement-content-section .content-no-preview {
  max-width: 100%;
}

.goodie-bag-merchant-table {
  margin-top: 30px;
  table-layout: fixed;
  width: 100%;
}

.goodie-bag-merchant-table tr th {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  word-wrap: break-word;
}

.goodie-bag-merchant-table tr td {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  word-wrap: break-word;
}

.lebel-right-button,
.lebel-right-button:hover,
.lebel-right-button:focus,
.lebel-right-button:active {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  color: $linkBtnColor;
  font-size: 16px;
  font-weight: 800;

  padding-top: 0px;
}

.goodie-bag-mini-program {
  margin-top: 30px;
  font-family: Mulish;
  font-style: normal;
}

.goodie-bag-mini-program-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
  margin-right: 5px;
}

.goodie-bag-mini-program-id {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  margin-right: 10px;
}

.base-simple-table.entitlement-tracking-code-simple-table {
  tr td {
    min-height: 128px;
    max-height: none;
  }
  tr td:nth-child(1) {
    min-width: 266px
  }
  tr td:nth-child(2) {
    width: 96px;
    padding: 8px;
  }
  tr td:nth-child(3), tr td:nth-child(4) {
    min-width: 125px;
  }
  tr td:nth-child(5), tr td:nth-child(6) {
    width: 300px;
    max-width: 300px;
  }
  tr td:last-child {
    width: 83px;
    max-width: 83px;
  }
}

.tracking-code-batch-manager .dropdown {
  z-index: 1 !important;
}

.loading-area div {
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4D5560;
}
