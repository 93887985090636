
.tracking-code-form {
    width: 800px;
    max-height: 969px;
    overflow-y: auto;
}

.tracking-code-container {
    width: 800px;
    max-height: 769px;
    overflow-y: auto;
}

.create-code-view-border {
    border-radius: 10px;
    border: 1px solid #DADADA;
    padding: 16px !important;

}

.create-code-view {
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    padding: 16px 0px;
    gap: 12px;

    .create-section-label-bottom-space {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .valid-period-area {
        .second-section {
            margin-top: 16px;
        }
    }

    .valid-period-follow-title {
        margin-top: 30px;
    }

    .valid-period-display {
        color: #404040;
    }

    input.custom-markdown-area-title {
        text-transform: uppercase;
    }
}

button.add-more {
    margin-top: 16px;
}