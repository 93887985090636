.check-otp-search-title {
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #002148;
}
.check-otp-search-container {
  width: 345px;
  margin: 40px 0px 73px 0px;
}
