.coupon-overview-export-button {
  position: absolute;
  right: 0;
  top: 0;
}

.coupon-overview-export-button .btn-back-button-common:disabled {
  background-color: #ffffff;
}

.raking-report-prompt-other-body {
  width: 820px;
}

.report-prompt-description {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8c8c8c;
}

.raking-report-prompt-other-body .other-body-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
  margin-top: 20px;
}

.raking-report-prompt-other-body .other-body-multi-selector {
  margin-top: 20px;
}

.raking-report-prompt-other-body .other-body-discription {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-top: 4px;
  margin-bottom: 12px;
}

.performance-report-prompt-other-body {
  width: 820px;
}

.performance-report-prompt-other-body .other-body-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
  margin-top: 20px;
}

.performance-report-prompt-other-body .other-body-multi-selector {
  margin-top: 20px;
}

.performance-report-prompt-other-body .other-body-multi-selector-loading {
  margin-top: 20px;
  height: 239px;
}

.performance-report-prompt-other-body .custom-radios-selector {
  display: inline-flex;
  flex-flow: row wrap;
  margin-top: 8px;
}
.performance-report-prompt-other-body
  .custom-radios-selector
  .radio-button-container {
  margin-right: 15px;
}

.performance-report-prompt-other-body .other-body-discription {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-top: 4px;
  margin-bottom: 12px;
}

// .performance-report-prompt-other-body
//   .custom-radios-selector
//   .radio-button-container:nth-child(3n) {
//   page-break-after: always; /* CSS 2.1 syntax */
//   break-after: always; /* CSS 3 syntax */

//   background: #979797;
// }
