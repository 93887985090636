.related-country-codes {
  display: block;
  margin: 10px 0 0;
}
.related-country-codes-item {
  color: #404040;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
}

.related-country-codes-prompt {
  padding: 25px 25px 25px 40px;

  .base-prompt-close-button {
    top: 25px;
    right: 25px;
  }

  .base-prompt-other-body {
    max-height: 280px;
    overflow-y: auto;

    ul.related-items-container {
      margin-top: 0;
    }
  }

  .base-prompt-buttons {
    display: none;
  }
}