@import '../../utils/Variables.scss';
.demo-image {
  height: 101px;
  object-fit: cover;
}

.demo-image-square {
  width: 110px;
}

.demo-image-circle{
  width: 180px !important;
  height: 180px !important;
  border-radius: 50%;
}

.demo-image-rectangle {
  width: 150px;
}

.uploading-background {
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.demo-image-div {
  position: relative;
  margin-right: 20px;
  margin-top: 16px;
}
.x-button {
  position: absolute;
  top: 4px;
  right: 4px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.show_cropped_image_btn {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  button {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #4e8dd7;
    background-color: #ffffff;
    border: 2px solid #4e8dd7;
    border-radius: 100px;
  }
}
.demo-image-section {
  display: flex;
  flex-wrap: wrap;
}
.custom-upload-btn,
.crop_image_btn {
  background: #ffffff;
  border: 2px solid #4e8dd7;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4e8dd7;
  padding: 6px 20px;
  margin-top: 16px;
  height: 34px;
  box-shadow: none;
  &:active {
    background: #e6f0fd;
    outline: 0px;
  }
  &:focus {
    outline: 0px;
  }
  &:disabled {
    background: #eeeeee;
    opacity: 0.6;
  }
}
.drop-upload-image-section {
  //   width: 132px;
  width: max-content;
  &:focus,
  > div:focus {
    outline: 0px;
  }
}
.modal-body {
  .cropper-bg {
    background-image: none;

    .cropper-modal {
      background-color: #ffffff;
    }
  }
}

.modal-content {
  max-width: calc((100vw - 60px));
}
