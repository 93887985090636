@import '../../utils/Variables.scss';
.dashboard-customer-card {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 28px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(154, 161, 171, 0.3) 0px 0px 10px;
  border-radius: 8px;
  min-height: 593px;
}
.dashboard-customer-card-title {
  margin-top: 30px;
  margin-left: 30px;
}
.dashboard-customer-card-data {
  margin-left: 41px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dashboard-customer-card-single-data {
  display: inline-block;
  width: 300px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
}
.dashboard-customer-card-hint-icon {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-top: 2px;
  object-fit: contain;
  align-self: center;
}
.dashboard-customer-card-info-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: $commentColor;
}
.dashboard-customer-card-info-number {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 12px;
  display: inline-block;
  margin-top: auto;
}
.dashboard-customer-card-chart-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $commentColor;
  margin-left: 41px;
  margin-top: 42px;
  margin-bottom: 30px;
}
.customers-overview-link {
  margin-right: 53px;
  margin-top: 20px;
  margin-bottom: 28px;
}
.dashboard-change-container {
  display: flex;
  flex-direction: row;
}
.dashboard-change-icon {
  width: 8px;
  height: 18px;
  margin-left: 2px;
}
.dashboard-change-number {
  margin-left: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
}

.dashboard-customer-card > div > div > svg > .recharts-layer > g > g > text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
