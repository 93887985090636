div.table-content {
  position: fixed;
  margin-top: 200px;
  width: calc(100vw - 240px);
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.time-period {
  display: flex;
  flex-direction: column;

  label {
    margin-left: 10px;
    display: flex;

    span {
      margin-left: 5px;
      color: red;

      align-self: flex-end;
    }
  }
}

.box {
  width: 10px;
  height: 10px;
  border-top: 3px solid red;
  border-right: 3px solid red;
  transform: rotate(135deg);
  margin-top: 5px;

  //   position: relative;
  //   width: 0;
  //   height: 0;
  //   border: 10px solid transparent;
  //   border-top: 10px solid red;
  //   border-bottom: none;
  //   margin-top: 5px;

  //   align-self: center;
}

.time-period:before {
  position: absolute;
  border: 10px solid red;
}
