.custom-tag-label-waiting {
  width: fit-content;
}

.common-status-area {
  min-width: 140px;
  width: auto;
  max-width: 200px;
}

.payment-settlement-disagree-content {
  display: block;
}

.payment-settlement-disagree-remark {
  display: block;
  font-size: 16px;
  width: 600px;
  height: 108px;
  left: 1px;
  top: 30px;
  border: 1px solid #c2c2c2;
  resize: none;
}

.payment-settlement-comment-area {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height:18px;
  height: 54px;       /* 3x line height */
  border: none;
  resize: none;
  background-color: #f7faff;
}

.payment-settlement-comment-area:focus {
  border: none;
  outline: none;
}

.payment-settlement-alert-info-prompt {
  overflow-y: hidden !important;
}