.create-logo-label-prompt-custom-class {
    padding: 40px 40px 22px 40px;
}

.create-logo-label-prompt-content {
    margin-top: 0;
    margin-bottom: 0;
    width: 684px;
}

.create-logo-label-prompt-languageTabs {
    box-shadow: none;
    position: absolute;
    margin-left: -40px;
    width: 764px;
}

.create-logo-label-prompt-languageTab0 {
    margin-left: 34px!important;
}

.create-logo-label-prompt-languageTab {
    margin-left: 60px!important;
}

.create-logo-label-prompt-section {
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;
    margin-left: -10px;
}

.create-logo-label-prompt-name-section {
    margin-top: 54px!important;
}
.create-logo-label-prompt-display-priority-section {
    margin-top: 6px;
}

.create-logo-label-prompt-button {
    margin-top: 10px!important;
    margin-right: -13px!important;
}