.btn-import-terms-and-conditions-template {
    margin-right: 30px;
}

.terms-and-conditions-single-delete {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #404040;
    max-width: 673px;
}

.terms-and-conditions-single-delete .terms-and-conditions-single-delete-description {
    margin-bottom: 20px;
}

.terms-and-conditions-confirm-to-delete {
    max-height: 500px;
    overflow: auto;
}

.terms-and-conditions-confirm-to-delete li {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 16px;
}