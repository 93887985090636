.card-container {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(172, 192, 215, 0.34);
  border-radius: 8px;
  width: calc(100% - 16px - 14px);
  margin: 16px;
  margin-right: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.title-container {
  display: flex;
  flex-direction: row;
  .title-id {
    height: 16px;
    margin-left: 4.35%;
    margin-top: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #4e8dd7;
  }
  .title-date {
    position: absolute;
    height: 16px;
    right: 4.35%;
    margin-top: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #404040;
  }
}
.title-devide-line {
  margin-left: 4.35%;
  margin-top: 8px;
  border: 0.5px solid #e9e9e9;
  width: calc(100% - 15px - 15px);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 14px;
  width: calc(100% - 15px - 15px);
  margin: 10px 15px 20px;
}

.content-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
  color: #404040;
  > div {
    max-width: 100%;
    word-wrap: break-word;
  }
}

.view-code-button {
  box-sizing: border-box;
  width: 141px;
  height: 41px;
  margin-left: 54.78%;
  margin-bottom: 20px;
  border: 1.70833px solid #4e8dd7;
  border-radius: 35.0208px;
}

.filter-back-button {
  width: 25px;
  height: 25px;
  top: 10px;
  left: 25px;
  position: absolute;
}

.no-record-page {
  height: 100%;
  margin-top: 15%;
  background-color: #fafbfe;
}

.record-loading {
  height: 100%;
}

.no-record-image {
  max-width: 100%;
}
