@import '../../../utils/Variables.scss';

.fortunebag-detail-table {
  border-color: #dadada;

  td {
    padding: 28px 21px 27px 21px;

    font-family: Mulish;
    font-style: normal;

    vertical-align: baseline;
  }

  td:nth-child(1) {
    width: 22%;
  }

  td:nth-child(2) {
    width: 14%;
  }

  td:nth-child(3) {
    width: 40%;
  }
  td:nth-child(4) {
    width: 24%;
  }

  thead > tr > td {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;

    color: #404040;
  }

  tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    color: #000000;
  }

  label.batch-dselimiter {
    color: #909090;

    margin-left: 10px;
    margin-right: 10px;
  }

  label.batch-updatetime {
    color: #909090;
  }

  label.batch-no {
    color: #404040;
  }
}
