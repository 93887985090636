.goodie-bag-group-list-caution-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 10px;
  width: 426px;
  max-width: 426px !important;
  height: 145px;
  background: rgba(0, 33, 72, 0.9);
  border-radius: 8px;
}
