.newline {
  white-space: pre-line;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #e2002c;
}

.prompt-error-message {
  max-width: 770px;
}
