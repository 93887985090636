.dashboard-goodiebag-card {
  margin: 32px 40px;
  width: initial;
  height: 272px;
}

.dashboard-goodiebag-card-sections {
  display: flex;
  flex-direction: row;
}

.dashboard-goodiebag-card-sections div.dashboard-coupon-item-container {
  width: 20%;
  margin-left: 0px;
  //   border: 1px solid;
  padding: 15px 10px;
}
