.customer {
  .scroll-container-common-header {
    justify-content: flex-start;
  }

  .scroll-container-long-screen {
    width: '100%';
  }

  // .detail-info-section-item {
  //   padding-top: 0px;
  // max-width: 860px;
  // }

  .detail-record-section-item {
    //   padding-top: 0px;
    // width: 995px;
    padding-bottom: 0px;
  }

  .custom-card-area {
    //   background: #ffffff;
    //   box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    //   border-radius: 8px;
    max-width: 860px;
  }

  .custom-record-table {
    margin-top: 30px;
  }

  .custom-record-table th,
  .custom-record-table td {
    border: 1px solid #dadada;

    font-family: Muli;
    font-style: normal;

    color: #404040;
    white-space: pre-wrap;
  }

  .custom-record-blod-font {
    font-family: Muli;
    font-style: normal;

    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  .custom-record-normal-font {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
  }

  .custom-record-table th {
    padding: 12px 20px;
  }

  .custom-record-table td {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    text-align: center;

    white-space: pre-wrap;
    height: 46px;
  }

  .custom-timeline-area {
    display: flex;
  }

  .custom-timeline-icon {
    display: grid;
    width: 40px;
    padding-top: 20px;
  }

  .custom-timeline-line {
    padding: 12px 6px 0px 6px;
  }

  .custom-timeline-font-color {
    color: #4d5560;
  }

  .custom-timeline-time-color {
    color: #404040;
  }

  .show-more-button {
    width: 82px;
    margin: 0px;
    padding: 0px;
  }

  .view-all-button {
    width: 282px;
    align-self: flex-end;
    margin-top: 31px;
    margin-bottom: 30px;
  }

  .no-data-area {
    padding: 30px 0px;
  }

  .edit-custom-transaction-selector {
    width: 220px;
  }

  .dropdown-short {
    width: 180px;
  }

  .dropdown-input-long {
    width: 355px;
  }

  .dropdown-mutiple-add-button {
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
  }

  .customer-detail-bottom-buttons {
    width: 100%;
    .base-save-button {
      width: 100%;
      margin: 0 24px
    }
  }
}

.custom-add-remove-mutiple-selector {
  display: inline-flex;
  flex-wrap: wrap;
}

.custom-add-remove-mutiple-selector-buttons {
  display: flex;
  max-height: 202px;
}
.custom-add-remove-mutiple-selector-buttons-line-break {
  width: 100%;
  height: 60px;
  .flex-row {
    height: 100%;
    max-width: 330px;
    width: 100%;
    justify-content: center;
    margin-left: -20px;
  }
  img {
    transform: rotate(90deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
  }
}

.custom-add-remove-mutiple-selector-buttons-area {
  margin-right: 10px;
  align-self: center;
}

.custom-add-remove-mutiple-selector-buttons-area button {
  margin-top: 5px;
}

.custom-add-remove-mutiple-selector .option-and-add-button {
  max-width: 330px;
  width: 100%;
}

.show-more-button {
  width: 82px;
  margin: 0px;
  padding: 0px;
  margin-right: auto;
  margin-left: 0px !important;
}

.goodiebag-acquire-summary-title {
  font-size: 18px;

}

.customer-goodiebag-record {
  .content-no-preview {
    max-width: unset;
  }

  .goodiebag-record-coupon-overview {
    .create-section-title {
      margin-bottom: 30px;
    }

    .goodiebag-record-coupon-card {
      margin: 0px;
      width: 100%;
      height: fit-content;
    }

    .sperate-line {
      width: 1px;
      background-color: #C2C2C2;
      height: 114px;
      margin: 15px 20px;
      border: none;
    }

    .dashboard-goodiebag-card-sections > div.d-flex {
      width: 40%;

      div {
        width: 50%;
      }
    }
  }

  div:has(> div.goodiebag-record-coupon-overview),  div:has(> div.goodiebag-record-coupon-overview) + div.create-step-section-item {
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
  }

  div:has(> div.goodiebag-record-coupon-overview) + div.create-step-section-item {
    .all-list-scrollable-area {
      padding: 0px;
    }

    .all-list-scrollable-area-unclickable-field {
      font-size: unset;
    }

    .all-list-component-action-header-div label {
      display: flex;
      flex-direction: column;


      span.customer-overview-row-title {
        margin-left: 0px;
      }

      span.dashboard-and-overview-as-of-label {
        margin-top: 5px;
      }
    }

    .goodiebag-coupon-filter {
      right: 10px;
    }

    .all-list-table th:last-child {
      right: unset;
    }

    .all-list-table td:last-child {
      right: unset;
      box-shadow: none;
      border-bottom: 0.5px solid #dadada;
    }

    .all-list-table tbody {
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */

      .common-status-area, td.common-status-area {
        padding: 20px 16px;
        vertical-align: baseline;
      }
    }
  }
}

.customer-activity-logs {
  tr th:nth-child(2), tr td:nth-child(2) {
    min-width: 150px;
  }
  tr th:nth-child(3), tr td:nth-child(3) {
    left: 272px;
  }
  tr th:nth-child(4), tr td:nth-child(4) {
    min-width: 182px;
    width: calc((100vw - 240px - 80px - 394px) * 0.25);
  }
  tr th:nth-child(6), tr td:nth-child(6),
  tr th:nth-child(8), tr td:nth-child(8),
  tr th:nth-child(9), tr td:nth-child(9),
  tr th:nth-child(12), tr td:nth-child(12) {
    min-width: 191px;
    width: unset;

    .all-list-scrollable-area-table-item-common {
      min-width: 182px;
      width: calc((100vw - 240px - 80px - 394px) * 0.25);
    }
  }
  tr th:nth-child(7), tr td:nth-child(7) {
    min-width: 150px;
  }
}



@media screen and (max-width: 768px) {
  .customer-goodiebag-record {
    .goodiebag-record-coupon-overview {
      .dashbard-coupon-card {
        min-width: initial;
      }

      .dashboard-goodiebag-card-sections {
        flex-direction: column;
      }

      .dashboard-goodiebag-card-sections > div.d-flex {
        width: 100%;
      }

      .sperate-line {
        display: none;
      }
    }

    .goodiebag-acquire-summary-title {
      text-transform: uppercase;
    }

    div:has(> div.goodiebag-record-coupon-overview) + div.create-step-section-item {
      margin-bottom: 0px;

      .header {
        background-color: transparent;
        padding-left: 0px;
        padding-right: 0px;

        label {
          display: flex;
          flex-direction: column;


          span.customer-overview-row-title {
            margin-left: 0px;
          }

          span.dashboard-and-overview-as-of-label {
            margin-top: 5px;
          }
        }
      }

      .total-count {
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .customer-mobile-list {
        padding: 0px;
      }

      .goodiebag-coupon-filter {
        right: 10px;
        top: 100px;
      }



      // .loading-area {
      //   height: 20px;
      // }
    }

    .create-section-and-preview-container {
      margin-bottom: 0px;
    }
  }

}

