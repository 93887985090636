.second-section-content.related-items-content {
  display: block;
  margin-bottom: 28px;
  .show-more {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
    color: #4e8dd7;
  }
  .detail-page-link {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4e8dd7;
  }
}
ul.related-items-container {
  padding-inline-start: 20px;
  margin-top: 20px;
  width: 60vw;
  li.related-items {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
  }
}

.detail-page-link {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4e8dd7;
}

.link-label-with-dot {
  margin-left: 10px;
}
