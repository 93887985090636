.manual-use-overview-list-section-header {
  min-height: inherit;
  padding-bottom: inherit;
  margin-top: calc(60px + 15px);
  background: #ffffff;
  display: inherit;
  padding-left: 15px;
  padding-right: 40px;
  width: 100%;
  flex-direction: column;
}

.manual-use-overview-filter-area-mobile {
  display: flex;
  width: 351px;
  position: fixed;
  right: 0;
  margin-left: auto;
  top: 0px;
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  padding: 45px 28px;
  z-index: 3;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}
