@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.toastui-editor-ww-container .toastui-editor-contents {
  padding: 10px 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}
.toastui-editor-contents {
  min-height: 236px !important;
  z-index: 1;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2 {
  border-bottom: none;
}

.customer-editor-container {
  max-width: 700px;
  height: 272px;
  position: relative;
}

.customer-editor-container-short {
  width: 619px;
  max-width: 100%;
}

.editor-container-error {
  border: 1px solid red;
}

.editor-container-error-message {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0000;
  padding: 0px;
  margin-top: 8px;
}

.toastui-editor-ww-container .toastui-editor-contents {
  color: #000000;
  font-size: 14px;
  font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
}

.toastui-editor-contents ul > li::before {
  margin-top: 9px;
  background-color: #000000;
}

.toastui-editor-contents ol > li::before {
  margin-left: -37px;
  color: #000000;
}

.toastui-editor-contents blockquote {
  color: #000000;
}

.toastui-editor-contents ul,
.toastui-editor-contents menu,
.toastui-editor-contents ol,
.toastui-editor-contents dir {
  color: #000000;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
  color: #000000;
}

.toastui-editor-contents blockquote p,
.toastui-editor-contents blockquote ul,
.toastui-editor-contents blockquote ol {
  color: #000000;
}

.editor-disable {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
}

.editor-disabled
  > div
  > div
  > div.te-editor-section
  > div
  > div.toastui-editor-ww-container
  > div.te-editor {
  background: #f7f7f7;
}
