.base-mark-down-area {
  display: flex;
  flex-direction: column;
}

.base-mark-down-input {
  max-width: 700px;
}

.link-to-terms-and-conditions-template-tips {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin: 8px 0px;
}